import { toast } from 'react-toastify';
import Api from '.';

const marcasProvider = {
  getMarcas: async (params) => {
    const { data } = await Api.get('/marcas', { params });
    return data;
  },
  updateMarca: async (id, data) => {
    const response = await Api.patch(`/marcas/${id}`, data);
    return response.data;
  },
  deleteMarca: async (id) => {
    const response = await Api.delete(`/marcas/${id}`);
    return response.data;
  },
  importMarcaInfos: async (data) => {
    const response = await Api.post('/marcas/import-infos', data);
    return response?.data || false;
  },
  createMarca: async (data) => {
    const response = await Api.post('/marcas', data);
    return response.data;
  },
  uploadProcessNumberMarks: async (demandId, data) => {
    return Api.patch(`/marcas/${demandId}/processNumberMarcas`, data)
      .then((response) => {
        toast.success('Marcas adicionadas com sucesso.');
        return response.data;
      })
      .catch((err) => {
        toast.error('Marca não vinculada a demanda');
      });
  },
  notifyMonitoring: async () => {
    return Api.post('/marcas/notifyMonitoring');
  },
};

export default marcasProvider;
