import { store } from '..';
import NewActiviteContactProvider from '../../providers/NewActiveContacts';

const fetchNewActiveContacts = async () => {
  const newActiveContact = store.getState().newActiviteContactReducer;

  const response = await NewActiviteContactProvider.getActiveContactsDashboard({
    ...newActiveContact.filters,
  });
  store.dispatch({
    type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
    payload: {
      activeContact: response || [],
    },
  });
};

const newActiveContactActions = {
  fetchNewActiveContacts,
};

export default newActiveContactActions;
