import React from 'react';

import dayjs from 'dayjs';
import { MdDelete } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import DispatchButton from '../../../../components/Buttons/DispatchButton';

const fupsColumns = [
  {
    name: 'Data de envio',
    selector: (row) => (
      <p>{dayjs(row.dateToSend).format('DD/MM/YYYY') || 'Não definida'}</p>
    ),
    sortFunction: (a, b) => {
      const aValue = a.dateToSend || '';
      const bValue = b.dateToSend || '';

      return aValue.localeCompare(bValue);
    },
  },

  {
    name: 'Foi enviado',
    selector: (row) =>
      row.sentAt ? <FaCheck fill="green" /> : <IoMdClose fill="red" />,
  },
  {
    name: 'Tipo de envio',
    cell: (row) => row.fupSendingType,
  },
  {
    name: 'Mensagem',
    cell: (row) => row?.fupMessage,
  },

  {
    name: 'Editar FUP',
    cell: (row) => (
      <DispatchButton
        dispatchKey="SET_MODAL_STATE"
        variant="dark"
        size="sm"
        style={{ fontSize: '12px' }}
        payload={{
          modalEditFup: {
            isOpen: true,
            fupId: row.id,
          },
        }}
      >
        Edit
      </DispatchButton>
    ),
  },
  {
    name: 'Ações',
    cell: (row) => (
      <DispatchButton
        dispatchKey="SET_MODAL_STATE"
        variant="transparent"
        size="sm"
        style={{ fontSize: '12px' }}
        payload={{
          modalDeleteFup: {
            isOpen: true,
            fupId: row.id,
          },
        }}
      >
        <MdDelete className="text-danger hover-pointer" size={30} />
      </DispatchButton>
    ),
  },
];

export const fupSendingTypeOptions = [
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'Digisac',
    label: 'Digisac',
  },
  {
    value: 'Demand',
    label: 'Demand',
  },
  {
    value: 'PopUp',
    label: 'PopUp',
  },
];

export default fupsColumns;
