/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import signProvider from '../../providers/sign';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import PDFViewerComponent from './PDFViwerComponent';
import ButtonGeneric from '../../components/Button';
import SignaturePad from './SignatureCanvas';
import FormGeneric from '../../components/Form';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import PlusIcon from '../../components/customIcons/PlusIcon';

export default function OnlySigningCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, signId, docSignatureId } = useParams();
  const [showSign, setDropSign] = useState(false);
  const { fileURL } = useSelector((state) => state.signReducer);
  const [signatureURL, setSignatureURL] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [awsFileKey, setawsFileKey] = useState([]);
  const [zoom, setZoom] = useState(1.5);
  const [docId, setDocumentId] = useState(id);
  const { showModalPadSign } = useSelector((state) => state.signReducer);

  const increaseZoom = () => setZoom((prevZoom) => prevZoom + 0.5);
  const decreaseZoom = () =>
    setZoom((prevZoom) => Math.max(prevZoom - 0.5, 0.5));

  const pdfViewerRef = useRef({});
  const signaturePadRef = useRef({});

  const uploadFile = async () => {
    const { error } = await signProvider.confirmSignature({
      signId,
      docId,
      awsFileKey,
    });
    if (error) {
      return toast.error(error);
    }
    return navigate(`/sign/`);
  };

  // eslint-disable-next-line consistent-return
  const getPrevious = async () => {
    if (coordinates.length > 0) {
      const versions = await signProvider.getPreviousDocumentVersions({
        where: {},
        signId,
        coordinates,
        docId,
      });

      setDocumentId(versions.documentId);
      setawsFileKey(versions.awsFileKey);
      setCoordinates(() => [...versions.coordinate]);
      return dispatch({
        type: 'SET_FILE_URL',
        payload: { fileURL: versions.url },
      });
    }
  };
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const geturl = async () => {
      if (fileURL === null || !fileURL || fileURL) {
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: null },
        });
        const versions = await signProvider.getPreviousDocumentVersions({
          where: {
            id: docSignatureId,
          },
          signId,
          docSignatureId,
        });

        if (!versions.url) {
          const url = await signProvider.getUrlView({
            where: {
              id: docId,
            },
          });
          return dispatch({
            type: 'SET_FILE_URL',
            payload: { fileURL: url },
          });
        }
        return dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: versions.url },
        });
      }
    };

    geturl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSignImg = async () => {
      const response = await signProvider.getSignImage({
        where: {
          id: signId,
        },
      });

      if (response && response.imageUrl) {
        setSignatureURL(response.imageUrl);
      }
    };
    fetchSignImg();
  }, [signId]);

  // eslint-disable-next-line consistent-return

  useEffect(() => {}, [fileURL]);
  const handlePositionSelected = async ({ x, y, pageNumber, zooms }) => {
    if (signatureURL) {
      const newCoordinates = {
        x,
        y,
        pageNumber,
        zooms,
        key: coordinates.length + 1,
      };

      const updatedCoordinates = [...coordinates, newCoordinates];
      setCoordinates(updatedCoordinates);

      const { url, error, documentId, awsKey } =
        await signProvider.drawSignature({
          where: { id: docId },
          coordinates: updatedCoordinates, // Envia todas as coordenadas acumuladas
          awsFileKey,
          signId,
          docSignatureId,
        });

      setDocumentId(documentId);
      setawsFileKey(awsKey);

      if (error) {
        toast.error('Erro ao adicionar assinatura.');
      } else {
        toast.success('Assinatura adicionada com sucesso.');
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: url },
        });
      }
    } else {
      toast.error(
        'Por favor, crie uma assinatura antes de selecionar o local.',
      );
    }
  };

  return (
    <>
      <TitlePage
        title="NDM Sign"
        description="Ferramenta para assinatura eletronica de documentos."
        className="p-4 border-bottom border-dark"
      />

      <MainPage>
        <div>
          <Row
            sm={12}
            className="d-flex text-center justify-content-between p-0 m-0 border-bottom"
          >
            <FormGeneric
              onSubmit={(e) => {
                uploadFile();
              }}
              className="d-flex justify-content-between"
            >
              <Col sm={12} className="d-flex justify-content-center w-100">
                {signatureURL !== 'onlySender' && (
                  <ButtonGeneric
                    onClick={() => setDropSign((prevShow) => !prevShow)}
                    className="text-white text-center w-25 mb-5"
                    type="button"
                    id="createSignature"
                  >
                    {signatureURL ? 'Editar Assinatura' : 'Criar Assinatura'}
                  </ButtonGeneric>
                )}
                <Dropdown.Menu
                  show={showSign}
                  align="center"
                  title="Adicionar signatarios"
                  className="w-25 mt-5"
                  style={{
                    top: '3%',
                    position: 'absolute',
                    zIndex: '1000',
                  }}
                >
                  {signatureURL && (
                    <Dropdown.Item className="d-flex justify-content-center">
                      {signatureURL !== 'onlySender' && (
                        <img
                          src={signatureURL}
                          className="border"
                          alt="Assinatura do usuário"
                          style={{
                            width: '150px',
                            height: '113px',
                          }}
                        />
                      )}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      dispatch({
                        type: 'SET_SHOW_MODAL',
                        payload: {
                          showModalPadSign: true,
                        },
                        // eslint-disable-next-line no-sequences
                      }),
                        setDropSign(false);
                    }}
                    className="d-flex align-items-center"
                  >
                    <PlusIcon className="me-2" /> Editar Assinatura
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Col>
            </FormGeneric>
          </Row>
          <Row
            sm={12}
            className="d-flex justify-content-start d-flex align-items-center mt-5"
          >
            <Col xl={4} className="  text-center d-flex justify-content-end">
              <ButtonGeneric onClick={decreaseZoom}>-</ButtonGeneric>
              <ButtonGeneric className="ms-3 me-3" onClick={increaseZoom}>
                +
              </ButtonGeneric>
            </Col>

            <Col className="text-center d-flex justify-content-start" xl={2}>
              <ButtonGeneric onClick={() => getPrevious()}>
                Desfazer
              </ButtonGeneric>
            </Col>
            <Col xl={5} className="  text-center d-flex justify-content-start">
              <FormGeneric
                onSubmit={(e) => {
                  uploadFile();
                }}
                className="w-100 d-flex justify-content-start align-items-center"
              >
                {coordinates.length > 0 ? (
                  <ButtonGeneric
                    className="w-50"
                    type="submit"
                    style={{
                      top: '10%',
                      left: '70%',
                      // position: 'absolute',
                    }}
                  >
                    Assinar e Enviar
                  </ButtonGeneric>
                ) : (
                  <p>
                    Clique na area do documento para posicionar a assinatura.
                  </p>
                )}
              </FormGeneric>
            </Col>
          </Row>
          <Row className="">
            <PDFViewerComponent
              zoom={zoom}
              signatureURL={signatureURL}
              ref={pdfViewerRef}
              onMouseOverEvent={handlePositionSelected}
            />
          </Row>
        </div>

        <ModalGeneric
          show={showModalPadSign}
          close={() => {
            dispatch({
              type: 'SET_SHOW_MODAL',
              payload: { showModalPadSign: false },
            });
          }}
          className="d-flex w-100 h-100 align-items-center"
          style={{
            height: '600px',
          }}
        >
          <SignaturePad
            ref={signaturePadRef}
            onSave={(url) => setSignatureURL(url)}
            onClose={() => {
              dispatch({
                type: 'SET_SHOW_MODAL',
                payload: { showModalPadSign: false },
              });
            }}
          />
        </ModalGeneric>
      </MainPage>
    </>
  );
}
