import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import newActiveContactActions from '../../../store/actions/newActiveContactActions';

export default function ModalPauseNewActiveContact() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const onSubmit = async (data) => {
    const body = {
      id: modal.modalPauseActiveContact.newActiveContactId,
      pausedAt: dayjs(),
    };
    if (modal?.modalPauseActiveContact?.isPaused) {
      body.pausedAt = null;
    }
    await NewActiviteContactProvider.update(body);

    resetModal();
    await newActiveContactActions.fetchNewActiveContacts();
  };

  return (
    <ModalConfirmation
      open={modal?.modalPauseActiveContact?.isOpen}
      close={resetModal}
      onConfirmation={onSubmit}
      title={
        modal?.modalPauseActiveContact?.isPaused
          ? 'Ao Confirmar você dará continuidade ao contato ativo'
          : 'Ao Confirmar você ira pausar o contato ativo'
      }
    />
  );
}
