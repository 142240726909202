import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import newActiveContactActions from '../../../store/actions/newActiveContactActions';

export default function ModalStartActiveContact() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    await NewActiviteContactProvider.startOfNewActiveContact({
      id: modal.modalStartActiveContact.newActiveContactId,
      fups: modal.modalStartActiveContact.fups,
      startedAt: dayjs(),
    });

    resetModal();
    await newActiveContactActions.fetchNewActiveContacts();
    navigate('/cs/activeContact/new');
  };

  return (
    <ModalConfirmation
      open={modal?.modalStartActiveContact?.isOpen}
      close={resetModal}
      onConfirmation={onSubmit}
      title="Ao Confirmar você ira iniciar o contato ativo"
    />
  );
}
