import React from 'react';

export default function TitlePage({ ...props }) {
  let border = 'border-bottom border-dark';

  if (props?.border === 'none') {
    border = '';
  }

  return (
    <section
      className="d-flex flex-column"
      style={{
        background: props.bg === true ? '#333333' : '',
      }}
    >
      <div className={props.className || `pb-3 ${border}`}>
        <h6>{props?.item}</h6>
        <h3>{props?.title}</h3>
      </div>
      {props?.description && (
        <div
          style={{
            color: props.bg === true ? 'white' : '',
          }}
          className={`ps-4 pt-4 pb-3 ${border}`}
        >
          <p>{props?.description}</p>
        </div>
      )}
    </section>
  );
}
