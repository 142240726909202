import React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import InputCalendar from './Input_Calendar';
import { holidaysDates } from '../../utils/DateUtils';

export default function InputSLACalendar({ ...props }) {
  const fups = useSelector((state) => state.newActiviteContactReducer.fups);

  const dateToday = dayjs().startOf('day').toISOString();

  const defaultValueToRender = [props?.row?.dateToSend] || [dateToday];
  if (defaultValueToRender[0] == null) {
    defaultValueToRender[0] = [dateToday];
  }
  return (
    <InputCalendar
      {...props} // eslint-disable-next-line react/no-unstable-nested-components
      tileContent={({ date }) => {
        const datesList = fups.map((fup) => dayjs(fup?.dateToSend).toDate());
        const findDate = datesList.filter((date_) =>
          dayjs(date_).isSame(dayjs(date), 'day'),
        );
        if (findDate.length > 0) {
          return (
            <p
              className="m-0 p-0"
              style={{
                fontSize: '10px',
              }}
            >
              {findDate.length} fup
            </p>
          );
        }
        return null;
      }}
      tileClassName={({ date }) => {
        const datesList = fups.map((f) => dayjs(f?.dateToSend).toDate());
        const findHoliday = holidaysDates.find(
          (date_) =>
            dayjs(date_).format('MM-DD') === dayjs(date).format('MM-DD'),
        );
        const findDate = datesList.filter((date_) =>
          dayjs(date_).isSame(dayjs(date), 'day'),
        );
        if (findDate.length === 1) {
          return 'hasOneDemand';
        }
        if (findDate.length > 1) {
          return 'hasMoreThanOneDemand';
        }
        if (findHoliday) {
          return 'hasHoliday';
        }
        return null;
      }}
      defaultValue={[defaultValueToRender]}
    />
  );
}
