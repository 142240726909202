import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { pdfjs, Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function PDFViewerComponent({ onMouseOverEvent, zoom, signatureURL }) {
  const { fileURL } = useSelector((state) => state.signReducer);
  const [numPages, setNumPages] = useState(null);

  const handleMouseOver = (event, pageNumber) => {
    const { clientX, clientY } = event.nativeEvent;

    // Obtém as dimensões do elemento atual
    const rect = event.target.getBoundingClientRect();

    // Calcula as coordenadas relativas
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;

    if (onMouseOverEvent) {
      onMouseOverEvent({
        x: offsetX / zoom,
        y: offsetY / zoom,
        pageNumber,
        zoom,
      });
    }
  };

  // eslint-disable-next-line no-shadow
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Row
      className="d-flex row-container m-0"
      style={{
        overflowY: 'auto',
        overflowX: 'auto',
        height: '900px', // Garante centralização vertical
        cursor: signatureURL !== 'onlySender' ? 'pointer' : 'default',
      }}
      md={6}
    >
      {fileURL && (
        <Document
          file={fileURL}
          onLoadSuccess={onDocumentLoadSuccess}
          className="d-flex flex-column  w-50 me-5" // Centraliza o PDF
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              className="border m-2 p-0 " // Remove justify-content-center no Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={zoom}
              onClick={(event) => {
                if (signatureURL !== 'onlySender') {
                  handleMouseOver(event, index + 1);
                }
              }}
            />
          ))}
        </Document>
      )}

      {(!fileURL || fileURL === null) && (
        <Col className="text-center">
          Este documento não pertence a este usuário
          <span style={{ fontSize: '20px', marginLeft: '8px' }}>&#128531;</span>
        </Col>
      )}
    </Row>
  );
}

export default PDFViewerComponent;
