import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import NewActiviteContactProvider from '../../../../providers/NewActiveContacts';
import ModalForm from '../../../../components/Modals/ModalForm';
import InputSelect from '../../../../components/Inputs/Input_Select';
import { fupSendingTypeOptions } from './fupsColumns';
import InputGeneric from '../../../../components/Inputs/Input_generic';
import InputFupCalendar from '../../../../components/Inputs/Input_FUP_Calendar';

export default function ModalEditFup() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const preStart = queryParam.get('preStart');
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };
  const fups = useSelector((state) => state.newActiviteContactReducer.fups);

  const fup = fups.find((f) => f.id === modal?.modalEditFup?.fupId);

  const onSubmit = async (data) => {
    await NewActiviteContactProvider.editFup({
      fupId: modal?.modalEditFup?.fupId,
      fupSendingType: data.fupSendingType,
      dateToSend: dayjs(data.dateToSend).toISOString(),
      fupMessage: data.fupMessage,
    });

    resetModal();
    window.location.reload();
  };

  const updateRedux = (data) => {
    const fupsUpdated = fups.map((f) => {
      if (f.id === modal?.modalEditFup?.fupId) {
        return {
          ...f,
          fupSendingType: data.fupSendingType,
          dateToSend: dayjs(data.dateToSend).toISOString(),
          fupMessage: data.fupMessage,
        };
      }
      return f;
    });

    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        fups: fupsUpdated,
      },
    });

    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalEditFup?.isOpen}
      close={resetModal}
      onSubmit={preStart ? updateRedux : onSubmit}
    >
      <h3>Edite o FUP</h3>
      <InputSelect
        name="fupSendingType"
        options={fupSendingTypeOptions}
        label="Nova Pasta do Documento"
        placeholder="Selecione um usuário"
        defaultValue={fup?.fupSendingType}
      />
      <InputFupCalendar name="dateToSend" label="Data" row={fup} />

      <InputGeneric
        type="text"
        as="textarea"
        name="fupMessage"
        label="Mensagem"
        defaultValue={fup?.fupMessage}
      />
    </ModalForm>
  );
}
