import { MdOutlineHome, MdPendingActions } from 'react-icons/md';
import {
  RiUserSettingsLine,
  RiCustomerService2Fill,
  RiRouteLine,
} from 'react-icons/ri';
import { HiUsers } from 'react-icons/hi';
import { PiUsersThree, PiUserList } from 'react-icons/pi';
import { AiOutlineTool } from 'react-icons/ai';
import { BsTags, BsCardList } from 'react-icons/bs';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { insertIdInArray } from '../../utils/GenericUtils';
import FinanceIcon from '../customIcons/FinanceIcon';

export const backofficeTabList = insertIdInArray([
  {
    title: 'Dashboard',
    href: '/',
    icon: MdOutlineHome,
  },
  {
    title: 'Demandas',
    icon: MdPendingActions,
    subMenuItems: insertIdInArray([
      { title: 'Criar', href: `/demands/new` },
      {
        title: 'Enviar em Massa',
        href: `/demands/new/massive`,
      },
      {
        title: 'Mensagem em Massa',
        href: `demands/new/massive/message`,
      },
      { title: 'Listar', href: `/demands` },
      { title: 'Painel', href: `/demands/board` },
      { title: 'Painel Pendentes', href: `/demands/board/pending` },
    ]),
  },
  {
    title: 'Jornadas',
    icon: RiRouteLine,
    subMenuItems: insertIdInArray([
      {
        title: 'Criar tipo de Jornada',
        href: `/journeys/types/create`,
      },
      {
        title: 'Listar tipos de Jornadas',
        href: `/journeys/types`,
      },
      {
        title: 'Criar Jornada de Cliente',
        href: `/journeys/customer-journey/create`,
      },
      {
        title: 'Listar Jornadas de Clientes',
        href: `/journeys/customer-journey`,
      },
    ]),
  },
  {
    title: 'Perfil',
    href: `/users/backoffice/profile`,
    icon: RiUserSettingsLine,
  },
  {
    title: 'Sucesso do Cliente',
    icon: RiCustomerService2Fill,
    subMenuItems: insertIdInArray([
      { title: 'Painel de demandas CS', href: `/cs/demands-panel` },
      { title: 'Contato Ativo', href: `/cs/activeContact` },
      { title: 'Renovações', href: `/cs/contracts` },
      { title: 'Feedbacks', href: `/cs/feedbacks/demand` },
      {
        title: 'Feedback Onboarding',
        href: `/cs/feedbacks/onboarding`,
      },
      {
        title: 'Feedbakck Contato Ativo',
        href: `/cs/feedbacks/activeContact`,
      },
      { title: 'Onboarding', href: `/cs/onboarding` },
      {
        title: 'Novo Contato Ativo',
        href: `/cs/activeContact/new`,
      },
    ]),
  },
  {
    title: 'Usuários',
    icon: PiUsersThree,
    subMenuItems: insertIdInArray([
      { title: 'Criar', href: `/users/backoffice/new` },
      { title: 'Listar Backoffice', href: `/users/backoffice/` },
      { title: 'Listar Clientes', href: `/users/customer/` },
      { title: 'Férias', href: `/users/vacations` },
    ]),
  },
  {
    title: 'Clientes',
    icon: PiUserList,
    subMenuItems: insertIdInArray([
      { title: 'Criar', href: `/customers/new` },
      { title: 'Listar', href: `/customers/` },
    ]),
  },
  {
    title: 'Ferramentas',
    icon: AiOutlineTool,
    subMenuItems: insertIdInArray([
      { title: 'ROPA', href: `/tools/ropa` },
      { title: 'Marcas', href: `/tools/marcas` },
      { title: 'Gerador de URL', href: `/tools/fileToUrl` },
      {
        title: 'Avaliação Interna de Risco',
        icon: FaRegCalendarAlt,
        nestedSubMenuItems: insertIdInArray([
          { title: 'Questões', href: `/air` },
          { title: 'Menu', href: `air/menu` },
        ]),
      },
      {
        title: 'Lembretes',
        icon: FaRegCalendarAlt,
        nestedSubMenuItems: insertIdInArray([
          { title: 'Criar', href: `/reminder/new` },
          { title: 'Listar', href: `/reminder` },
          { title: 'Envio em Massa', href: `/reminder/send` },
        ]),
      },
      {
        title: 'RH',
        icon: HiUsers,
        href: '/rh/businessSearch',
      },
    ]),
  },
  {
    title: 'Tags',
    icon: BsTags,
    subMenuItems: insertIdInArray([
      { title: 'Criar', href: `/tag/new` },
      { title: 'Listar', href: `/tag` },
    ]),
  },
  {
    title: 'Tipos de Demanda',
    icon: BsCardList,
    subMenuItems: insertIdInArray([
      { title: 'Criar', href: `/demand-types/new` },
      { title: 'Listar', href: `/demand-types` },
    ]),
  },
  {
    title: 'Área de demanda',
    icon: BsCardList,
    subMenuItems: insertIdInArray([
      { title: 'Criar', href: `/demand-categories/new` },
      { title: 'Listar', href: `/demand-categories` },
    ]),
  },
  {
    title: 'Parceiros',
    icon: HiUsers,
    subMenuItems: insertIdInArray([
      { title: 'Criar', href: `/partners/new` },
      { title: 'Listar', href: `/partners` },
    ]),
  },
]);

export const customerTabList = insertIdInArray([
  {
    title: 'Inicio',
    href: '/',
    icon: MdOutlineHome,
  },
  {
    title: 'Demandas',
    icon: MdPendingActions,
    href: `/demands`,
  },
  {
    title: 'Documentos',
    icon: RiUserSettingsLine,
    href: `/documents`,
  },
  {
    title: 'Jornadas',
    icon: RiRouteLine,
    href: `/journeys`,
  },
  {
    title: 'Ferramentas',
    icon: AiOutlineTool,
    href: `/tools`,
  },
]);

export const customerSuperAdminTabList = insertIdInArray([
  ...customerTabList,
  ...[
    {
      title: 'Financeiro',
      icon: FinanceIcon,
      href: `/financial`,
    },
    {
      title: 'Negócios',
      icon: PiUsersThree,
      href: `/business`,
    },
  ],
]);

export const customerFinanceTabList = insertIdInArray([
  ...customerTabList,
  {
    title: 'Financeiro',
    icon: FinanceIcon,
    href: `/financial`,
  },
]);

export default backofficeTabList;
