import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Hotjar from '@hotjar/browser';

// eslint-disable-next-line import/no-unresolved
import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import SideBar from './SideBar';
import TopBar from './TopBar';
import {
  backofficeTabList,
  customerFinanceTabList,
  customerSuperAdminTabList,
  customerTabList,
} from './SideBarData';
import Loading from '../Loading';
import ModalDemand from '../Modals/ModalDemand';
import ModalSeach from '../Modals/ModalSeach';
import ModalPaymentOverdue from '../Modals/ModalPaymentOverdue';
import CookiesBanner from '../Banners/CookiesBanner';
import usePageTracking from '../../utils/usePageTracking';

export default function Layout() {
  const authUser = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const rest = {
    tabList: [],
  };
  switch (authUser.type) {
    case 'BACKOFFICE':
      rest.tabList = backofficeTabList;
      break;
    case 'CUSTOMER':
      if (authUser.customerSuperAdmin) {
        rest.tabList = customerSuperAdminTabList;
      } else if (authUser.customerIsFinance) {
        rest.tabList = customerFinanceTabList;
      } else {
        rest.tabList = customerTabList;
      }
      break;
    default:
      break;
  }

  useEffect(() => {
    const apiURL = process.env.REACT_APP_API_URL.replace('/api', '');
    const socket = io(apiURL, {
      transports: ['websocket'],
    });
    socket.on('connect', () => {});

    socket.on('fupPopUp', (data) => {
      if (data?.backofficeUserId === authUser?.id) {
        toast.success(data.message);
      }
    });

    socket.on('emailMessage', (data) => {
      dispatch({
        type: 'SET_MESSAGE_USER_ID',
        payload: { userId: data?.userId },
      });
      if (data?.backofficeUserId === authUser?.id) {
        toast.success(
          `Mensagem do customer ${data?.customerName} recebida no contato ativo`,
        );
      }
    });

    socket.on('webhook-event', (data) => {
      dispatch({
        type: 'SET_MESSAGE_USER_ID',
        payload: { userId: data?.userId },
      });
      if (data?.backofficeUserId === authUser?.id) {
        toast.success(
          `Mensagem do customer ${data?.customerName} recebida no contato ativo`,
        );
      }
    });

    socket.on('disconnect', () => {});

    return () => {
      socket.disconnect();
    };
  }, [authUser?.id, dispatch]);

  const isloading = useSelector(({ loading }) => loading);

  const acceptCookies = localStorage.getItem('@ndm-cookies-hotjar');
  if (acceptCookies === 'true') {
    Hotjar.init(
      Number(process.env.REACT_APP_HOTJAR_ID || 2453457),
      Number(process.env.REACT_APP_HOTJAR_VERSION || 6),
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePageTracking();
  }

  return (
    <div className="sidebar-wrapper position-relative">
      <Loading loading={isloading} />
      <ModalDemand />
      <ModalPaymentOverdue />
      <CookiesBanner />
      <ModalSeach />
      <div className="d-flex">
        <SideBar {...rest} />
        <section
          className="d-flex flex-column"
          style={{
            flex: 1,
            background: 'rgb(245, 245, 245)',
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <TopBar />
          <main
            style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}
            className="pt-5 pb-0 ps-2 pe-2"
          >
            <Outlet />
          </main>
        </section>
      </div>
    </div>
  );
}
