import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Dropdown, Row, Col } from 'react-bootstrap';
import { FaRegBell } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { BiSearchAlt } from 'react-icons/bi';
import { useNavigate, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import { PiSidebar } from 'react-icons/pi';
import ModalGeneric from '../Modals/ModalGeneric';
import UserNotificationProvider from '../../providers/userNotification';
import userProvider from '../../providers/user';
import AllowedTo from '../AllowedTo';
import ButtonGeneric from '../Button';
import SmartLink from '../SmartLink';
import InputSelect from '../Inputs/Input_Select';
import ColorBall from '../ColorBall';
import FormatString from '../../utils/FormatString';
import PlusIcon from '../customIcons/PlusIcon';
import { optionsForAll, optionsUserCustomer } from './TopBarData';
import ndmSign from '../../assets/img/image.png';

export default function TopBar() {
  const [notification, setNotification] = useState([]);
  const businesses = useSelector((state) => state.businesses);
  const authUser = useSelector((state) => state.auth.auth);
  const collapseButtonUsing = useSelector(
    (state) => state.layout.collapseButtonUsing,
  );
  const setCollapseButtonUsing = (value) => {
    dispatch({ type: 'SET_COLLAPSE_BUTTON_USING', payload: value });
  };

  const [showSecondModal, setShowSecondModal] = useState(
    // !localStorage.getItem('modalClosedSign'),
    false,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  async function fecth() {
    const user = await userProvider.userLogged();
    const userNotification = await UserNotificationProvider.getNotifications({
      page: 1,
      limit: 5,
    });
    dispatch({ type: 'SET_AUTH', payload: user });
    setNotification(userNotification);
  }

  const handleCloseSecondModal = () => {
    localStorage.setItem('modalClosedSign', 'true');
    setShowSecondModal(false);
  };

  useEffect(() => {
    fecth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const querys = new URLSearchParams(location.search);
  const businessId = querys.get('businessId');
  const isValidUser = businesses.some((business) => business.id === businessId);

  if (businessId && isValidUser) {
    localStorage.setItem('businessId', businessId);
  }

  const actualSelectedBusiness = localStorage.getItem('businessId');
  const defaultBusinessValue = businesses.find(
    (business) => business.id === actualSelectedBusiness,
  );

  const initialsOfUserName = FormatString.getInitials(
    authUser?.name,
  ).toUpperCase();

  let optionsOfCurrentUser = optionsForAll;

  if (authUser.type === 'CUSTOMER') {
    optionsOfCurrentUser = optionsUserCustomer;
  }

  const handleCollapse = () => {
    dispatch({ type: 'SET_COLLAPSE_BUTTON_USING', payload: '' });
  };

  return (
    <Navbar
      expand="lg"
      className="p-2 bg-white text-dark "
      style={{
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        zIndex: 3,
      }}
    >
      <ModalGeneric
        style={{ position: 'relative' }}
        onHide={handleCloseSecondModal}
        show={showSecondModal === true}
        close={() => handleCloseSecondModal()}
        overflowScroll
      >
        <Row>
          <h3 className="text-center">
            ✍️ Conheça o NDM Sign: sua nova ferramenta de assinatura eletrônica!
          </h3>
          <p className="text-justify mt-4">
            Apresentamos o <strong>NDM Sign</strong>, a solução completa para
            assinaturas eletrônicas, desenvolvida conforme o ICP-Brasil, Lei nº
            14.063/20 e Medida Provisória nº. 2200-2/2001. Com esta nova
            funcionalidade, os clientes NDM agora podem assinar e enviar
            documentos e contratos com agilidade, segurança jurídica e
            praticidade.
          </p>
        </Row>

        <Row>
          <p className="text-justify mt-4">
            <strong>Por que usar o NDM Sign?</strong>
          </p>
          <ul>
            <li>
              <strong>Rapidez:</strong> Assine e compartilhe documentos em
              poucos cliques, diretamente na plataforma NDM.
            </li>
            <li>
              <strong>Facilidade:</strong> Todos os contratos e documentos que
              estiverem na plataforma da NDM podem ser gerenciados e assinados
              sem complicações.
            </li>
            <li>
              <strong>Segurança jurídica:</strong> Total conformidade com as
              leis, garantindo validade e proteção para suas negociações.
            </li>
            <li>
              <strong>Redução de custos:</strong> É mais barato assinar com o
              NDM Sign.
            </li>
            <li>
              <strong>Integração total:</strong> Por ser uma funcionalidade
              nativa da NDM, você não precisa contratar plataformas externas.
            </li>
          </ul>
          <Row>
            <img
              className="d-block w-100"
              src={ndmSign}
              alt="Imagem de Design Responsivo"
              style={{ border: '1px solid black' }}
            />
          </Row>
          <p className="text-justify mt-4">
            Experimente o <strong>NDM Sign</strong> agora e simplifique a gestão
            e assinatura dos seus documentos.
          </p>
          <p className="text-center mt-3">
            ✍️<strong>NDM Sign:</strong> a maneira mais fácil e rápida de
            assinar seus documentos com segurança jurídica sem precisar
            contratar outras ferramentas.
          </p>
          <SmartLink className="text-center mt-4" to="/sign/" target="_blank">
            <ButtonGeneric onClick={() => handleCloseSecondModal()}>
              <strong>ACESSAR O NDM SIGN</strong>
            </ButtonGeneric>
          </SmartLink>
        </Row>
      </ModalGeneric>

      <ButtonGeneric
        variant="outline"
        className="border-0 text-decoration-none d-lg-none"
        role="button"
        hidden={collapseButtonUsing === 'topbar'}
        onClick={() => {
          if (collapseButtonUsing === 'sidebar') {
            setCollapseButtonUsing('');
          } else {
            setCollapseButtonUsing('sidebar');
          }
          dispatch({ type: 'TOGGLE_MENU' });
        }}
      >
        <PiSidebar size={30} />
      </ButtonGeneric>
      <Navbar.Toggle
        hidden={collapseButtonUsing === 'sidebar'}
        bsPrefix="d-lg-none ms-auto btn"
        aria-controls="mynavbara"
        onClick={() => {
          if (collapseButtonUsing === 'topbar') {
            setCollapseButtonUsing('');
          } else {
            setCollapseButtonUsing('topbar');
          }
        }}
      >
        <RxHamburgerMenu size={30} />
      </Navbar.Toggle>

      <Navbar.Collapse
        in={collapseButtonUsing === 'topbar'}
        className=""
        id="mynavbar"
      >
        <Row className="m-0 w-100">
          <Col
            className="align-self-center align-content-center m-auto ms-lg-0"
            xs="auto"
          >
            <AllowedTo userType="CUSTOMER">
              <SmartLink to="/demand/new" onClick={() => handleCollapse()}>
                <ButtonGeneric
                  className="border-0 text-decoration-none text-white"
                  id="customerCreateDemandButton"
                >
                  <PlusIcon
                    className="mb-1"
                    padded={false}
                    fill="#fff"
                    width={14}
                    height={14}
                  />{' '}
                  Criar demanda
                </ButtonGeneric>
              </SmartLink>
            </AllowedTo>
          </Col>
          <AllowedTo userType="CUSTOMER">
            <Col className="align-self-center p-0" lg="auto">
              <span>Negócio:</span>
            </Col>
            <Col lg="4" xl="3">
              <InputSelect
                options={businesses.map((business) => ({
                  value: business.id,
                  label: business.name,
                }))}
                onChange={(value) => {
                  localStorage.setItem('businessId', value);
                  window.location.reload();
                }}
                defaultValue={{
                  value: defaultBusinessValue?.id,
                  label: defaultBusinessValue?.name,
                }}
              />
            </Col>
          </AllowedTo>
          <Col lg="auto">
            <Nav className="d-flex">
              <AllowedTo userType="BACKOFFICE">
                <BiSearchAlt
                  className="hover hover-pointer mx-2"
                  size={30}
                  onClick={() => {
                    dispatch({
                      type: 'SET_SEACH_STATE',
                      payload: {
                        open: true,
                      },
                    });
                  }}
                />
              </AllowedTo>
              <Row>
                <Col xs="auto pt-1 order-2">
                  <Dropdown id="topBarBellDropDown" align="end">
                    <Dropdown.Toggle className="bg-white text-dark border-0 hover">
                      <FaRegBell className="" size={24} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="p-2"
                      style={{
                        minWidth: '250px',
                        position: 'absolute',
                      }}
                    >
                      {notification.map((n) => (
                        <Dropdown.Item
                          className=""
                          key={n.title}
                          onClick={() => {
                            navigate(
                              `${
                                authUser.type === 'CUSTOMER'
                                  ? '/demand/'
                                  : '?demandId='
                              }${n.link}`,
                            );
                            handleCollapse();
                          }}
                        >
                          <div className="border-bottom pb-2 mb-1">
                            <Row
                              className="fs-14 text-bold "
                              style={{ whiteSpace: 'normal' }}
                            >
                              {n.title}
                            </Row>
                          </div>
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Item
                        onClick={() => navigate('/users/notifications')}
                      >
                        <p className="fs-14 text-center pt-2 m-0 pm-2 hover">
                          Ver Tudo
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs="auto">
                  <Dropdown
                    id="topBarProfileMenu"
                    align="start"
                    className="border-none"
                  >
                    <Dropdown.Toggle
                      id="topBarProfileMenuToggle"
                      className="d-flex align-items-center bg-white text-dark border-0 hover"
                    >
                      <ColorBall
                        size={35}
                        marginSize={0}
                        backgroundColor="#C2986A"
                      >
                        <span className="text-white fs-12 fw-bold">
                          {initialsOfUserName}
                        </span>
                      </ColorBall>

                      <span className="ms-2 fs-14 fw-bold d-lg-block d-md-none">
                        {authUser?.name}
                      </span>
                    </Dropdown.Toggle>
                    {/* <div className="ms-3"> */}
                    <Dropdown.Menu style={{ position: 'absolute' }}>
                      {optionsOfCurrentUser.map((option) => (
                        <Dropdown.Item
                          key={option.label}
                          className="d-flex my-1 align-items"
                          onClick={option?.onClick}
                        >
                          <p>{option.icon}</p>
                          <p className="mx-4">{option.label}</p>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Nav>
          </Col>
        </Row>
      </Navbar.Collapse>
    </Navbar>
  );
}
