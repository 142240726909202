import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userProvider from '../../../providers/user';
import { defaultGetAllParams } from '../../../utils/params';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';
import newActiveContactActions from '../../../store/actions/newActiveContactActions';
import ButtonGeneric from '../../../components/Button';
import ModalGeneric from '../../../components/Modals/ModalGeneric';

export default function ModalAddUser() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const fetch = async () => {
    const response = await userProvider.getAll({
      ...defaultGetAllParams,
      where: {
        key: 'customerId',
        value: modal?.modalAddUser?.customerId,
        operator: 'eq',
      },
    });
    setUsers(response?.rows || []);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [modal?.modalAddUser?.isOpen]);

  const toggleUser = async (userId) => {
    await NewActiviteContactProvider.addUserToActiveContact({
      newActiveContactId: modal?.modalAddUser?.newActiveContactId,
      userId,
    });

    await newActiveContactActions.fetchNewActiveContacts();
    resetModal();
  };
  return (
    <ModalGeneric show={modal?.modalAddUser?.isOpen} close={resetModal}>
      <h3>Usuários vinculados</h3>
      {users?.filter((user) => {
        return modal?.modalAddUser?.activeContactUsers?.find(
          (u) => u?.userId === user?.id,
        );
      }).length > 0 ? (
        users
          ?.filter((user) => {
            return modal?.modalAddUser?.activeContactUsers?.find(
              (u) => u?.userId === user?.id,
            );
          })
          .map((user) => (
            <ButtonGeneric
              key={user.id}
              variant="dark"
              className="text-white"
              type="button"
              style={{
                margin: '0.25rem 0.2rem',
                fontSize: '12px',
                width: 'fix-content',
              }}
              onClick={() => toggleUser(user.id)}
            >
              {user.name}
            </ButtonGeneric>
          ))
      ) : (
        <p>Não há usuários vinculados</p>
      )}

      <h5 className="text-bold mt-2">Adicionar Usuários</h5>
      {users
        ?.filter((user) => {
          return !modal?.modalAddUser?.activeContactUsers?.find(
            (u) => u?.userId === user?.id,
          );
        })
        .map((user) => (
          <ButtonGeneric
            key={user.id}
            variant="dark"
            className="text-white"
            type="button"
            style={{
              margin: '0.25rem 0.2rem',
              fontSize: '12px',
              width: 'fix-content',
            }}
            onClick={() => toggleUser(user.id)}
          >
            {user.name}
          </ButtonGeneric>
        ))}
    </ModalGeneric>
  );
}
