import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../../pages/Login';
import RecoverPassword from '../../pages/Login/RecoverPassword';
import PoliticsRoutes from './Politics.routes';
import SignPage from '../../pages/Sign/SignPage';

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/resetPassword" element={<RecoverPassword />} />
      <Route path="/politicas/*" element={<PoliticsRoutes />} />
      <Route path="*" element={<Login />} />
      <Route path="/signAuth/*" element={<SignPage />} />
    </Routes>
  );
}
