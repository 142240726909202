import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TitlePage from '../../../../components/Pages/TitlePage';
import MainPage from '../../../../components/Pages/MainPage';
import NewActiviteContactProvider from '../../../../providers/NewActiveContacts';
import TableSection from '../../../../components/Table/SectionTable';
import fupsColumns from './fupsColumns';
import ModalEditFup from './ModalEditFups';
import ButtonGeneric from '../../../../components/Button';
import ModalStartActiveContact from '../ModalStartActiveContact';
import ModalDeleteFup from './ModalDeleteFup';

export default function FupsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const newActiveContactId = queryParam.get('newActiveContactId');
  const preStart = queryParam.get('preStart');
  const activiteContactRedux = useSelector(
    (state) => state.newActiviteContactReducer,
  );

  const fetch = async () => {
    const response = await NewActiviteContactProvider.getFups({
      newActiveContactId,
    });
    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        fups: response,
      },
    });
  };

  const fetchPreStart = async () => {
    const response =
      await NewActiviteContactProvider.preStartOfNewActiveContact({
        newActiveContactId,
      });
    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        fups: response,
      },
    });
  };

  useEffect(() => {
    if (newActiveContactId && !preStart) {
      fetch();
    }

    if (preStart) {
      fetchPreStart();
    }

    return () => {
      dispatch({
        type: 'RESET_NEW_ACTIVITE_CONTACT_STATE',
      });
    };

    // eslint-disable-next-line
  }, [newActiveContactId]);

  return (
    <>
      <TitlePage
        title="FUPs"
        item="Follow Ups feitos"
        description="aqui você pode ver os FUPs a serem feitos"
      />
      <MainPage>
        <TableSection
          title="FUPS"
          columns={fupsColumns}
          data={activiteContactRedux?.fups || []}
        />
        <div />
        <ModalEditFup />
        {preStart && (
          <ButtonGeneric
            className="mt-2"
            onClick={() => {
              dispatch({
                type: 'SET_MODAL_STATE',
                payload: {
                  modalStartActiveContact: {
                    isOpen: true,
                    newActiveContactId,
                    fups: activiteContactRedux.fups,
                  },
                },
              });
            }}
          >
            Iniciar Contato
          </ButtonGeneric>
        )}
        <ModalStartActiveContact />
        <ModalDeleteFup />
      </MainPage>
    </>
  );
}
