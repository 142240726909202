import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignPage from '../../pages/Sign/SignPage';
import UserSignAuth from '../../pages/Sign/UserSignAuth';
import OnlySignPage from '../../pages/Sign/OnlySigning';

export default function SignRoutes() {
  return (
    <Routes>
      <Route path="/:token" element={<UserSignAuth />} />
      <Route
        path="/signing/:id/:signId/:docSignatureId"
        element={<SignPage />}
      />
      <Route
        path="/onlysigning/:id/:signId/:docSignatureId"
        element={<OnlySignPage />}
      />
      <Route
        path="/onlysigning/:id/:signId/:docSignatureId/:token"
        element={<OnlySignPage />}
      />
    </Routes>
  );
}
