import React from 'react';
import { Col, Row } from 'react-bootstrap';

import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import UploadPDFForm from './UploadPDFForm';

export default function UploadPDF() {
  return (
    <>
      <TitlePage
        title="NDM Sign"
        description="Ferramenta para assinatura eletronica de documentos."
      />
      <MainPage>
        <Row>
          <Col
            sm={12}
            className="justify-content-center"
            style={{ hieght: '600px' }}
          >
            <UploadPDFForm />
          </Col>
        </Row>
      </MainPage>
    </>
  );
}
