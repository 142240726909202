/* eslint-disable no-console */
import { toast } from 'react-toastify';
import Api from './index';

const AIRProvider = {
  questionsAnsweredByUser: async (
    customerId,
    businessId,
    options = { loadings: true },
  ) => {
    try {
      const response = await Api.get(
        `/question-air/${customerId}/${businessId}`,
        { params: options },
      );
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  getQuestionBySector: async (data) => {
    try {
      const response = await Api.post('/question-air/questionBySector', data);
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },

  createSector: async (data) => {
    try {
      const response = await Api.post('sector-air', data);
      toast.success('Setor criado com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  getAllSectors: async () => {
    try {
      const response = await Api.get('sector-air', {
        params: {
          limit: 999,
        },
      });
      return response.data?.rows;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  getSectorById: async (id) => {
    try {
      const response = await Api.get(`sector-air/${id}`);
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  updateSector: async (id, data) => {
    try {
      const response = await Api.patch(`sector-air/${id}`, data);
      toast.success('Setor atualizado com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  deleteSector: async (id) => {
    try {
      const response = await Api.delete(`sector-air/${id}`);
      toast.success('Setor deletado com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },

  createQuestion: async (data) => {
    try {
      const response = await Api.post('question-air', data);
      toast.success('Questão criada com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  getAllQuestions: async () => {
    try {
      const response = await Api.get('question-air');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  updateQuestion: async (id, data) => {
    try {
      const response = await Api.patch(`question-air/${id}`, data);
      toast.success('Questão atualizada com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  deleteQuestion: async (id) => {
    try {
      const response = await Api.delete(`question-air/${id}`);
      toast.success('Questão deletada com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },

  createAnswer: async (data) => {
    try {
      const response = await Api.post('/answer-air', data);
      toast.success('Resposta criada com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  getAllAnswers: async () => {
    try {
      const response = await Api.get('/answer-air');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  updateAnswer: async (id, data) => {
    try {
      const response = await Api.patch(`/answer-air/${id}`, data);
      toast.success('Resposta atualizada com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
  deleteAnswer: async (id) => {
    try {
      const response = await Api.delete(`/answer-air/${id}`);
      toast.success('Resposta deletada com éxito');
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },

  calculateRisk: async (data) => {
    try {
      const response = await Api.post(
        '/answerCustomer-air/calculateRisk',
        data,
      );
      return response.data;
    } catch (error) {
      return toast.error(error.message);
    }
  },
};

export default AIRProvider;
