/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProgressBar from 'react-bootstrap/ProgressBar';
import signProvider from '../../providers/sign';
import PDFViewerComponent from './PDFViwerComponent';
import img from '../../assets/img/logo-ndm.png';
import ButtonGeneric from '../../components/Button';
import SignaturePad from './SignatureCanvas';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import AnimatedButton from '../../components/Buttons/AnimatedButton';
import './style.css';
import PlusIcon from '../../components/customIcons/PlusIcon';
import s3 from '../../providers/urlGenerator';

export default function OnlySigning() {
  const dispatch = useDispatch();
  const { id, signId, docSignatureId, token } = useParams();
  const { fileURL } = useSelector((state) => state.signReducer);
  const [signatureURL, setSignatureURL] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [awsFileKey, setawsFileKey] = useState([]);
  const [showSign, setDropSign] = useState(false);
  const [zoom, setZoom] = useState(1.5);
  const [docId, setDocumentId] = useState(id);
  const [check, setCheck] = useState(id);
  const { showModalPadSign } = useSelector((state) => state.signReducer);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const increaseZoom = () => setZoom((prevZoom) => prevZoom + 0.5);
  const decreaseZoom = () =>
    setZoom((prevZoom) => Math.max(prevZoom - 0.5, 0.5));

  const pdfViewerRef = useRef({});
  const signaturePadRef = useRef({});

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const check = async () => {
      const checking = await signProvider.checkProcessingSign({
        token,
        signId,
      });
      setCheck(checking.isFinalized);
      if (checking.signedCount > 0) {
        setProgress(50);
      }
      // eslint-disable-next-line consistent-return
      const geturl = async () => {
        if (fileURL === null || !fileURL || fileURL) {
          dispatch({
            type: 'SET_FILE_URL',
            payload: { fileURL: null },
          });
          const url = await signProvider.getUrlView({
            where: {
              id: docId,
            },
          });
          return dispatch({
            type: 'SET_FILE_URL',
            payload: { fileURL: url },
          });
        }
      };

      geturl();

      return checking.isFinalized;
    };
    setawsFileKey('');
    check();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const getPrevious = async () => {
    if (coordinates.length > 0) {
      const versions = await signProvider.getPreviousDocumentVersions({
        where: {},
        signId,
        coordinates,
        docId,
      });

      setDocumentId(versions.documentId);
      setawsFileKey(versions.awsFileKey);
      setCoordinates(() => [...versions.coordinate]);
      return dispatch({
        type: 'SET_FILE_URL',
        payload: { fileURL: versions.url },
      });
    }
  };

  useEffect(() => {
    const fetchSignImg = async () => {
      const response = await signProvider.getSignImage({
        where: {
          id: signId,
        },
      });

      if (response && response.imageUrl) {
        setSignatureURL(response.imageUrl);
      }
    };
    fetchSignImg();
  }, [signId]);

  useEffect(() => {}, [fileURL]);
  const handlePositionSelected = async ({ x, y, pageNumber, zooms }) => {
    setDropSign(false);
    if (signatureURL) {
      const newCoordinates = {
        x,
        y,
        pageNumber,
        zooms,
        key: coordinates.length + 1,
      };

      const updatedCoordinates = [...coordinates, newCoordinates];
      setCoordinates(updatedCoordinates);

      const { url, error, documentId, awsKey } =
        await signProvider.drawSignature({
          where: { id: docId },
          coordinates: updatedCoordinates,
          awsFileKey,
          signId,
          docSignatureId,
        });

      if (!error && url && documentId) {
        setDocumentId(documentId);
        setawsFileKey(awsKey);
        if (progress < 50) {
          // eslint-disable-next-line consistent-return
          setProgress((prevProgress) => {
            if (prevProgress < 50) {
              return prevProgress + 50;
            }
          });
        }
      }

      if (error) {
        toast.error('Erro ao adicionar assinatura.');
      } else {
        toast.success('Assinatura adicionada com sucesso.');
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: url },
        });
      }
    } else {
      toast.error(
        'Por favor, crie uma assinatura antes de selecionar o local.',
      );
    }
  };

  const getHash = async () => {
    const { signatureHistory } = await signProvider.getHistory({
      docSignatureId,
    });

    const i = await signProvider.getHash({
      documentId: id,
      signatureHistory,
    });

    const fileNameParsedToPDF = `${i.fileName.replace(/\.[^/.]+$/, '')}.pdf`;

    await s3.downloadFileByUrl(i.newPdfUrl.signedUrl, fileNameParsedToPDF);
    return i;
  };

  useEffect(() => {
    if (progress >= 0 && progress < 49) {
      if (signatureURL) {
        setMessage(
          `${progress}%  Arraste sua assaintura para o local desejado.`,
        );
      }
      if (!signatureURL) {
        setMessage(`${progress}%  Crie sua assinatura.`);
      }
    } else if (progress >= 50 && progress < 100) {
      setMessage('Ótimo. Agora revise o documento e clique em Assinar.');
    } else if (progress === 100) {
      setMessage('Assinatura Completa.');
    } else {
      setMessage('');
    }
  }, [progress, signatureURL]);
  const endSign = async () => {
    const { error } = await signProvider.confirmSignature({
      signId,
      docId,
      awsFileKey,
    });
    if (error) {
      return toast.error(error);
    }
    await signProvider.endSigning({ token });
    return setProgress(100);
  };

  return (
    <Row className="m-0">
      {check === true ? (
        <>
          <Row
            className="d-flex mb-5 p-5 align-items-center m-0"
            style={{
              background: '#333333',
              transition: 'all 0.5s ease',
            }}
          >
            <Col
              md={5}
              className="d-flex flex-wrap align-items-center m-0 p-0 justify-content-between flex-row flex-md-row"
            >
              <Col
                sm={12}
                xl={6}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  className=""
                  style={{
                    width: '250px',
                  }}
                  src={img}
                  alt="imagem"
                />
              </Col>
              <Col
                xl={6}
                sm={12}
                className="d-flex justify-content-center align-items-center"
              >
                <p className="text-white m-0 p-0 me-5 mt-3">
                  Assine seu documento com <strong>SEGURANÇA 🔒</strong>.
                </p>
              </Col>
            </Col>

            <Col md={4} className=" m-0 p-0 justify-content-center">
              <span className="text-white">{message}</span>
              <ProgressBar now={progress} animated label={`${progress}%`} />
            </Col>

            <Col
              style={{}}
              className={`d-flex text-white ms-5 justify-content-end  ${
                progress === 100 ? 'header-expanded' : ''
              }`}
            >
              {progress === 100 && (
                <a
                  href="https://materiais.ndmadvogados.com.br/whatsapp?utm_source=landing_page&utm_medium=ndm_sign&utm_campaign=lp_wpp&utm_content=pagina_assinatura_NDM_Sign"
                  className="text-white ms-5"
                  target="_blank"
                  style={{
                    fontSize: '40px',
                    textDecoration: 'none',
                  }}
                  rel="noreferrer"
                >
                  <h5
                    className="p-2 mt-3 border border-radius"
                    style={{
                      background: '#CBA780',
                      borderRadius: '3%',
                      width: 'auto',
                      padding: '5px 10px',
                      transition: 'all 0.5s ease',
                      opacity: 1,
                    }}
                  >
                    Quero contratar os serviços da NDM.
                  </h5>
                </a>
              )}
            </Col>
          </Row>
          <Row
            sm={12}
            className="df-flex text-center justify-content-between p-0 m-0 border-bottom"
          >
            <Col sm={8} className="d-flex text-center justify-content-end">
              {signatureURL !== 'onlySender' && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {progress < 100 && (
                    <ButtonGeneric
                      onClick={() => setDropSign((prevShow) => !prevShow)}
                      className="text-white text-center w-50 mb-5"
                      type="button"
                      id="createSignature"
                    >
                      {signatureURL ? 'Editar Assinatura' : 'Criar Assinatura'}
                    </ButtonGeneric>
                  )}
                </>
              )}

              <Dropdown.Menu
                show={showSign}
                align="center"
                title="Adicionar signatarios"
                className="w-25 mt-5"
                style={{
                  // top: '20%',
                  // position: 'absolute',
                  zIndex: '1000',
                }}
              >
                {signatureURL && (
                  <Dropdown.Item className="d-flex justify-content-center">
                    {signatureURL !== 'onlySender' && (
                      <img
                        src={signatureURL}
                        className="border"
                        alt="Assinatura do usuário"
                        style={{
                          width: '150px',
                          height: '113px',
                        }}
                      />
                    )}
                  </Dropdown.Item>
                )}

                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    dispatch({
                      type: 'SET_SHOW_MODAL',
                      payload: {
                        showModalPadSign: true,
                      },
                      // eslint-disable-next-line no-sequences
                    }),
                      setDropSign(false);
                  }}
                  className="d-flex align-items-center"
                >
                  <PlusIcon className="me-2" /> Editar Assinatura
                </Dropdown.Item>
              </Dropdown.Menu>
            </Col>
            {progress === 100 && (
              <Col
                md={4}
                className="d-flex align-items-center justify-content-center position-absolute"
                style={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999, // Mantém o elemento acima de todos os outros
                  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Opcional: fundo branco semi-transparente
                  padding: '20px',
                  borderRadius: '10px', // Opcional: bordas arredondadas
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Opcional: sombra para destacar o elemento
                }}
              >
                {progress === 100 && (
                  <>
                    <h3
                      className="p-4"
                      style={{
                        transition: 'all 0.5s ease',
                      }}
                    >
                      Assinatura concluída ✅
                    </h3>

                    <a
                      href="http://ndmadvogados.com.br/"
                      className="text-white"
                      target="_blank"
                      style={{
                        fontSize: '40px',
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      <h5
                        className="p-3 border border-radius"
                        style={{
                          background: '#CBA780',
                          borderRadius: '3%',
                          width: 'auto',
                          padding: '10px 20px',
                          transition: 'all 0.5s ease',
                          opacity: 1,
                        }}
                      >
                        Ir para o site da NDM.
                      </h5>
                    </a>
                    <ButtonGeneric
                      className="text-white"
                      target="_blank"
                      style={{
                        fontSize: '40px',
                        textDecoration: 'none',
                        background: 'transparent',
                        border: 'none',
                      }}
                      onClick={() => getHash()}
                      rel="noreferrer"
                    >
                      <h5
                        className="p-3 "
                        style={{
                          background: '#CBA780',
                          borderRadius: '3%',
                          width: 'auto',
                          padding: '10px 20px',
                          transition: 'all 0.5s ease',
                          opacity: 1,
                        }}
                      >
                        Baixar Documento.
                      </h5>
                    </ButtonGeneric>
                  </>
                )}
              </Col>
            )}
          </Row>

          <Row
            sm={12}
            className="d-flex justify-content-start d-flex align-items-center mt-5"
          >
            <Col
              sm={2}
              md={2}
              lg={2}
              xl={4}
              className="  text-center d-flex justify-content-end"
            >
              <ButtonGeneric onClick={decreaseZoom}>-</ButtonGeneric>
              <ButtonGeneric className="ms-3 me-3" onClick={increaseZoom}>
                +
              </ButtonGeneric>
            </Col>

            <Col
              sm={2}
              md={2}
              lg={2}
              className="text-center d-flex justify-content-start"
              xl={2}
            >
              <ButtonGeneric onClick={() => getPrevious()}>
                Desfazer
              </ButtonGeneric>
            </Col>
            {progress < 100 && (
              <Col
                sm={4}
                md={5}
                lg={5}
                xl={5}
                className="  text-center d-flex justify-content-start"
              >
                {coordinates.length > 0 || progress < 100 ? (
                  <AnimatedButton
                    style={{
                      width: 'auto',
                      padding: '10px 20px',
                      transition: 'all 0.5s ease',
                      opacity: 1,
                    }}
                    type="submit"
                    endSign={endSign}
                    placeholder="Assinar"
                  />
                ) : (
                  <p>
                    Clique na area do documento para posicionar a assinatura.
                  </p>
                )}
              </Col>
            )}
          </Row>

          <Row md={12} className="">
            <PDFViewerComponent
              zoom={zoom}
              ref={pdfViewerRef}
              onMouseOverEvent={progress < 100 && handlePositionSelected}
            />
          </Row>

          <ModalGeneric
            show={showModalPadSign}
            close={() => {
              dispatch({
                type: 'SET_SHOW_MODAL',
                payload: { showModalPadSign: false },
              });
            }}
            className="d-flex w-100 h-100 align-items-center"
            style={{
              height: '600px',
            }}
          >
            <SignaturePad
              ref={signaturePadRef}
              onSave={(url) => setSignatureURL(url)}
              onClose={() => {
                dispatch({
                  type: 'SET_SHOW_MODAL',
                  payload: { showModalPadSign: false },
                });
              }}
            />
          </ModalGeneric>
        </>
      ) : (
        <Row className="d-flex vh-100 vw-100 dg-dark m-0 p-0 justify-content-center">
          <Row
            style={{
              background: 'black',
              margin: 0,
              padding: 0,
              display: 'flex',

              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="d-flex flex-column justify-content-center m-0 text-center"
          >
            <Col
              sm={12}
              xl={6}
              className="d-flex justify-content-center align-items-center mt-5 "
            >
              <img className="w-50 mt-5" src={img} alt="imagem" />
            </Col>

            <h2 className="text-white mt-5">Este documento ja foi assinado.</h2>
            <ButtonGeneric
              className="text-white mt-5 w-25 mb-5"
              onClick={() => getHash()}
            >
              Baixar Documento
            </ButtonGeneric>
            <PDFViewerComponent
              zoom={zoom}
              ref={pdfViewerRef}
              onMouseOverEvent={handlePositionSelected}
            />
          </Row>
        </Row>
      )}
    </Row>
  );
}
