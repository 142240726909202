import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import NewActiviteContactProvider from '../../../../providers/NewActiveContacts';

export default function ModalDeleteFup() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const preStart = queryParam.get('preStart');

  const fups = useSelector((state) => state.newActiviteContactReducer.fups);

  const onConfirmation = async () => {
    if (preStart) {
      const newFups = fups.filter((f) => f.id !== modal.modalDeleteFup.fupId);
      dispatch({
        type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
        payload: {
          fups: newFups,
        },
      });
      resetModal();
    } else {
      await NewActiviteContactProvider.deleteFup(modal.modalDeleteFup.fupId);
      window.location.reload();
    }
  };
  return (
    <ModalConfirmation
      open={modal.modalDeleteFup?.isOpen}
      close={resetModal}
      onConfirmation={onConfirmation}
      title="Excluir FUP"
      description="Ao clicar em confirmar, o FUP será excluído permanentemente."
    />
  );
}
