/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../../components/Modals/ModalForm';
import InputSelect from '../../../components/Inputs/Input_Select';
import newActiveContactActions from '../../../store/actions/newActiveContactActions';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';
import schemaUpdateBackofficeActiviteContact from '../../../validators/schemas/activiteContact';
import userProvider from '../../../providers/user';
import { searchBackofficeParams } from '../../../utils/params';

export default function ModalUpdateResponsible() {
  const modal = useSelector((state) => state.modal);

  const activiteContactRedux = useSelector(
    (state) => state.newActiviteContactReducer,
  );

  const dispatch = useDispatch();
  const toClose = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const fetch = async () => {
    const UsersbackofficeResponse = await userProvider.getAll(
      searchBackofficeParams,
    );

    dispatch({
      type: 'SET_NEW_ACTIVITE_CONTACT_STATE',
      payload: {
        users: UsersbackofficeResponse?.rows?.map((user) => ({
          value: user.id,
          label: user.name,
        })),
      },
    });
    dispatch({
      type: 'SET_BACKOFFICE_LIST',
      payload: UsersbackofficeResponse?.rows,
    });
  };

  useEffect(() => {
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActiveContactResponsible = async (data) => {
    await NewActiviteContactProvider.updateActiveContactResponsible(
      modal?.currentActiveContact.id,
      data,
    );
    await newActiveContactActions.fetchNewActiveContacts();
    toClose();
  };

  return (
    <ModalForm
      open={modal.modalEditResponsible?.isOpen}
      close={() => toClose()}
      title="Atualizar responsável pelo contato ativo"
      onSubmit={updateActiveContactResponsible}
      schema={schemaUpdateBackofficeActiviteContact}
    >
      <p className="d-flex">
        Responsável atual:{' '}
        <Link
          className="ms-2 text-decoration-none fw-bold"
          to={`/users/backoffice/${modal?.currentUserBackoffice?.id}`}
        >
          {modal?.currentUserBackoffice?.name}
        </Link>
      </p>
      <InputSelect
        options={activiteContactRedux?.users}
        name="backofficeUserId"
        label="Usuário Responsável"
        defaultValue={modal?.currentUserBackoffice?.id}
        width="18rem"
      />
    </ModalForm>
  );
}
