/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ButtonGeneric from '../../components/Button';
import DateUtils from '../../utils/DateUtils';

const pendingCurrentSignerColumns = (siningPendingDocs) => [
  {
    name: 'Nome',
    selector: (row) => row.doc.fileName,
    sortable: true,
    cell: (row) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <span>{row?.doc?.fileName}</span>
    ),
  },
  {
    name: 'Status',
    cell: (row) => (
      <Row className=" aligm-itens-center justify-content-center text-center ">
        <Col sm={12} className="mt-4">
          <span
            className=""
            style={{
              borderRadius: '40px',
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          >
            Pendente
          </span>
          <span style={{ marginLeft: '8px' }}>⚠️</span>
        </Col>
        <Col sm={12} className="mt-3 d-flex flex-column">
          <span
            style={{
              fontSize: '15px',
            }}
            className="mt-2"
          >
            Criado em: {DateUtils.formatToLocaleWithHour(row?.doc?.createdAt)}
          </span>
        </Col>
      </Row>
    ),
  },
  {
    name: 'Ações',
    cell: (row) => (
      <div className=" text-center">
        <ButtonGeneric
          onClick={() => siningPendingDocs(row)}
          className="text-center text-white"
          variant="primary"
        >
          Assinar PDF
        </ButtonGeneric>
      </div>
    ),
  },
];

export default pendingCurrentSignerColumns;
