import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmation from '../ModalConfirmation';
import demandsProvider from '../../../providers/demands';
import DEMAND_CONSTANTS from '../../../constants/demands';

export default function PendingDefaulterDemand() {
  const dispatch = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);

  const resetDataModal = () => {
    dispatch({
      type: 'RESET_MODAL_DEMAND',
    });
  };
  const onConfirmation = async () => {
    await demandsProvider.updateDemandStatus(demand.id, {
      statusKey: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.PENDING.key,
      previousStatusBeforeMarker: demand.statusKey,
      defaulterMarker: true,
    });
    const demandResponse = await demandsProvider.getDemandById(demand.id);
    dispatch({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };
  if (!demand) return null;
  return (
    <ModalConfirmation
      show={modalType === 'Pendente por Inadimplência'}
      close={() => resetDataModal()}
      title="Mover demanda para pendente por inadimplência"
      description="Essa ação irá mover a demanda para o status de pendente por inadimplência, as demandas movidas para pendente por essa razão serão movidas para o status anterior após a quitação do débito."
      onConfirmation={onConfirmation}
    />
  );
}
