import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LiaStreetViewSolid } from 'react-icons/lia';
import { useSelector } from 'react-redux';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputMask from '../../../components/Inputs/Input_Mask';

export default function FormInfoContact() {
  const customer = useSelector((state) => state.customer);
  return (
    <>
      <h5 className="mt-5 pb-2 border-bottom">Informações de Contato</h5>
      <Row>
        <Col>
          <InputGeneric
            name="email"
            label="E-mail"
            placeholder="Digite o e-mail do cliente"
            icon={LiaStreetViewSolid}
            defaultValue={customer?.data?.email}
          />
        </Col>
        <Col>
          <InputGeneric
            name="countryCode"
            label="Código do país"
            placeholder="Digite o código do país"
            defaultValue="55"
            icon={LiaStreetViewSolid}
          />
        </Col>
        <Col>
          <InputMask
            type="phone"
            name="phone"
            label="Telefone"
            placeholder="Digite o telefone do cliente"
            icon={LiaStreetViewSolid}
            defaultValue={customer?.data?.phone}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="site"
            label="Site"
            placeholder="Digite o site do cliente"
            icon={LiaStreetViewSolid}
            defaultValue={customer?.data?.site}
          />
        </Col>
      </Row>
    </>
  );
}
