import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BiSend } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderModalDemand from './HeaderModalDemand';
import AsideModalDemand from './AsideModalDemand';
import ChatModalDemand from './ChatModalDemand';
import RichTextArea from '../../Inputs/Input_RichTextArea';
import FormGeneric from '../../Form';
import schemaSendMessageDemand from '../../../validators/schemas/demands/sendMessage';
import ModalTags from './ModalTags';
import DetailsDemand from './DetailsDemand';
import ModalStatusKey from './ModalStatusKey';
import ButtonGeneric from '../../Button';
import s3 from '../../../providers/urlGenerator';
import ModalSigilose from './ModalSigilose';
import ModalBusiness from './ModalBusiness';
import ModalMessagePrivate from './ModalMessagePrivate';
import ModalUpdateSLA from './ModalUpdateSLA';
import ModalTypeDemand from './ModalTypeDemand';
import ModalPriority from './ModalPriority';
import ModalAlterTitle from './ModalAlterTitle';
import ModalGeneratorUsers from './ModalGeneratorUser';
import ModalJourney from './ModalJourney';
import ModalMark from './ModalMark';
import ModalUpdateSLAIntern from './ModalUpdateSLAIntern';
import ModalScreen from './ModalScreen';
import ModalUpdateResponsible from './ModalUpdateResponsible';
import ModalAddReminder from './ModalAddReminder';
import demandsProvider from '../../../providers/demands';
import ModalRemoveReminder from './ModalRemoveReminder';
import ModalCategoryDemand from './ModalCategoryDemand';
import PendingDefaulterDemand from './PendingDefaulterDemand';

export default function ModalDemand() {
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const demandId = queryParam.get('demandId');
  const navigate = useNavigate();

  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const authUser = useSelector((state) => state.auth.auth);
  const streaks = useSelector((state) => {
    return state.demandsStreak.demandsByStreak;
  });

  useEffect(() => {
    if (demandId) {
      fetch();
    }
    dispatch({
      type: 'RESET_FILES_STATE',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }

    if (demand) {
      if (
        !demand.backofficeVisualized?.includes(authUser.id) &&
        authUser?.type === 'BACKOFFICE'
      ) {
        const newDemandStreak = streaks[
          demand?.backofficeUserName || 'Pré-triagem'
        ] || {
          rows: [],
          count: 0,
          page: 1,
        };

        newDemandStreak.rows = (
          streaks[demand?.backofficeUserName || 'Pré-triagem'] || {
            rows: [],
          }
        ).rows.map((d) => {
          if (d.id === demand.id) {
            return {
              ...d,
              backofficeVisualized: [
                ...(d.backofficeVisualized || []),
                authUser.id,
              ],
            };
          }
          return d;
        });
        dispatch({
          type: 'SET_DEMANDS_STREAK',
          payload: {
            title: demand?.backofficeUserName || 'Pré-triagem',
            demands: newDemandStreak,
            loading: false,
          },
        });
        demandsProvider.setBackofficeVisualized(demand.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demand, authUser?.id, authUser?.type, dispatch]);

  const [clearRichText, setClearRichText] = useState();

  const handleSubmit = async (data) => {
    if (data.message.message.trim() === '') {
      toast.error('Mensagem não pode ser vazia');
      return;
    }
    const attachments = await Promise.all(
      data?.message.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }) || [],
    );
    const request = {
      content: data.message.message,
      attachments,
    };

    await demandsProvider.createMessage(demand.id, request);
    await clearRichText();
    await fetch();

    dispatch({
      type: 'RESET_FILES_STATE',
    });
  };

  async function copyLink() {
    const linkGenerated = await demandsProvider.generateCustomerDemandSiteUrl(
      demandId,
    );
    navigator.clipboard.writeText(linkGenerated.url);
    toast.success('Link copiado');
  }

  async function fetch() {
    const demandResponse = await demandsProvider.getDemandById(demandId);
    if (demandResponse?.id) {
      dispatch({
        type: 'SET_DEMANDS_STATE',
        payload: {
          data: {
            demand,
            ...demandResponse,
          },
        },
      });
    } else {
      toast.error('Demanda não encontrada');
      navigate('/demands');
    }
  }

  useEffect(() => {
    if (modalType === 'Copiar Link da Demanda') {
      copyLink();
      dispatch({
        type: 'STATE_MODAL_DEMAND',
        payload: { modalType: null },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  if (!demand) {
    return null;
  }

  return (
    !!demandId && (
      <div
        className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
        style={{
          background: 'rgba(0, 0, 0, 0.5)',
          top: '0',
          left: '0',
          zIndex: '10',
        }}
      >
        <Card
          style={{
            width: '95%',
            height: '95%',
          }}
        >
          <Card.Header closeButton>
            <HeaderModalDemand />
          </Card.Header>
          <Card.Body
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              padding: '0',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                overflowY: 'hidden',
              }}
            >
              {modalType !== 'Detalhes' ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      overflowY: 'auto',
                      height: '100%',
                    }}
                    ref={scrollRef}
                  >
                    <ChatModalDemand />
                  </div>
                  <Card.Footer
                    style={{
                      padding: '0',
                      maxHeight: '40%',
                    }}
                  >
                    <FormGeneric
                      onSubmit={handleSubmit}
                      schema={schemaSendMessageDemand}
                      style={{
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          padding: '5px',
                          paddingTop: '0',
                          height: '100%',
                        }}
                      >
                        <RichTextArea
                          keyId={`d${demandId}`}
                          saveToType="sendMessage"
                          name="message"
                          setClearRichText={setClearRichText}
                        />
                        <ButtonGeneric
                          type="submit"
                          className="d-flex justify-content-center align-items-center border-0"
                          style={{
                            borderRadius: '0',
                            width: '45px',
                            height: '100%',
                            padding: '0',
                            margin: '0',
                          }}
                        >
                          <BiSend size={25} color="white" />
                        </ButtonGeneric>
                      </div>
                    </FormGeneric>
                  </Card.Footer>
                </div>
              ) : (
                <DetailsDemand />
              )}
            </div>
            <div
              style={{
                overflowY: 'scroll',
                background: 'rgb(247,249,250)',
                borderLeft: '1px solid #ccc',
              }}
            >
              <AsideModalDemand />
            </div>
          </Card.Body>
        </Card>
        {modalType === 'Tags' && <ModalTags />}
        {modalType === 'Alterar status' && <ModalStatusKey />}
        {(modalType === 'Tornar  sigilosa' ||
          modalType === 'Tornar não sigilosa') && <ModalSigilose />}
        {modalType === 'Vincular à um lembrete' && <ModalAddReminder />}
        {modalType === 'Desvincular do lembrete' && <ModalRemoveReminder />}
        {modalType === 'Alterar negócio' && <ModalBusiness />}
        {modalType === 'Mensagem privada' && <ModalMessagePrivate />}
        {modalType === 'Alterar SLA' && <ModalUpdateSLA />}
        {modalType === 'Alterar tipo' && <ModalTypeDemand />}
        {modalType === 'Alterar categoria' && <ModalCategoryDemand />}
        {modalType === 'Priorizar' && <ModalPriority />}
        {modalType === 'Alterar título' && <ModalAlterTitle />}
        {modalType === 'Notificar Usuário' && <ModalGeneratorUsers />}
        {modalType === 'Adicionar a Jornada' && <ModalJourney />}
        {modalType === 'Número de Processo' && <ModalMark />}
        {modalType === 'Alterar SLA Interno' && <ModalUpdateSLAIntern />}
        {modalType === 'Definir Responsável' && <ModalScreen />}
        {modalType === 'Alterar Responsável' && <ModalUpdateResponsible />}
        {modalType === 'Pendente por Inadimplência' && (
          <PendingDefaulterDemand />
        )}
      </div>
    )
  );
}
