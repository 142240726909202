import React, { useRef } from 'react';
// eslint-disable-next-line import/no-unresolved
import SignatureCanvas from 'react-signature-canvas';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ButtonGeneric from '../../components/Button';
import signProvider from '../../providers/sign';

export default function SignaturePad({ onSave, onClose }) {
  const sigCanvas = useRef(null);

  const { signId } = useParams();
  const clear = () => {
    sigCanvas.current.clear();
    return toast.success('A sua assinatura foi limpa');
  };

  const save = async (event) => {
    const trimmedDataURL = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    const resizedDataURL = await resizeImage(trimmedDataURL, 151, 113);

    try {
      await signProvider.insertSignImage({
        signatureImage: resizedDataURL,
        signId,
      });
      onSave(trimmedDataURL);
      toast.success('Assinatura salva com sucesso');
      onClose();
    } catch (error) {
      toast.error('Erro ao salvar a assinatura');
    }
  };

  const resizeImage = (base64Image, width, height) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const resizedDataURL = canvas.toDataURL('image/png');
        resolve(resizedDataURL);
      };
      img.onerror = (error) => reject(error);

      img.src = base64Image;
    });
  };

  return (
    <Row className="w-100 h-100 pb-5 m-0 text-center">
      <SignatureCanvas
        ref={sigCanvas}
        canvasProps={{
          className: 'signature-canvas border h-100 w-100',
        }}
      />

      <Col sm={12}>
        <ButtonGeneric onClick={save} type="submit">
          Salvar Assinatura
        </ButtonGeneric>
        <ButtonGeneric className="ms-2" type="button" onClick={clear}>
          Limpar
        </ButtonGeneric>
      </Col>
    </Row>
  );
}
