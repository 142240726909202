/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import ButtonGeneric from '../Button';

export default function AnimatedButton({
  progress,
  className,
  placeholder,
  endSign,
}) {
  return (
    <>
      <ButtonGeneric
        className={className}
        onClick={endSign}
        type="submit"
        style={{
          opacity: 1,
          width: '500px',
          transform: 'scale(1)',
          transition: 'opacity 1s ease, transform 0.5s ease',
        }}
      >
        {placeholder}
      </ButtonGeneric>
    </>
  );
}
