import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard';
import Layout from '../../components/Layout';
import ToolsRoutes from '../pages/tools.routes';
import UsersRoutes from '../pages/users.routes';
import CustomerSuccessRoutes from '../pages/customerSuccess.routes';
import DemandsRoutes from '../pages/demands.routes';
import JourneyRoutes from '../pages/journey.routes';
import CustomerJourneyRoutes from '../pages/CustomerJourney.routes';
import DemandTypeRoutes from '../pages/demand-type.routes';
import TagRoutes from '../pages/tag.routes';
import BusinessRoutes from '../pages/Business.routes';
import CustomerRoutes from '../pages/customers.routes';
import AIRRoutes from '../pages/AIR.routes';
import ReminderRoutes from '../pages/Reminder.routes';
import userProvider from '../../providers/user';
import CustomerIndex from '../../pages/Dashboard/CustomerIndex';
import FinancialIndex from '../../pages/Financial';
import DocumentsRoutes from '../pages/Documents.routes';
import DemandRoutes from '../pages/demand.routes';
import ProfilesRoutes from '../pages/Profiles';
import PoliticsRoutes from './Politics.routes';
import PartnersRoutes from '../pages/Partners.routes';
import RhRoutes from '../pages/rh';
import DemandCategoriesRoutes from '../pages/demand-categories.routes';
import SignRoutes from '../pages/Sign';

const BackofficeRoutes = (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/*" element={<Dashboard />} />
      <Route path="/users/*" element={<UsersRoutes />} />
      <Route path="/tools/*" element={<ToolsRoutes />} />
      <Route path="/cs/*" element={<CustomerSuccessRoutes />} />
      <Route path="/demands/*" element={<DemandsRoutes />} />
      <Route path="/demand-types/*" element={<DemandTypeRoutes />} />
      <Route path="/demand-categories/*" element={<DemandCategoriesRoutes />} />
      <Route path="/tag/*" element={<TagRoutes />} />
      <Route path="/business/*" element={<BusinessRoutes />} />
      <Route
        path="/customer/:customerId/journey/:journeyId/*"
        element={<CustomerJourneyRoutes />}
      />
      <Route path="/journeys/*" element={<JourneyRoutes />} />
      <Route path="/customers/*" element={<CustomerRoutes />} />
      <Route path="/air/*" element={<AIRRoutes />} />
      <Route path="/reminder/*" element={<ReminderRoutes />} />
      <Route path="/tools/*" element={<ReminderRoutes />} />
      <Route path="/politicas/*" element={<PoliticsRoutes />} />
      <Route path="/partners/*" element={<PartnersRoutes />} />
      <Route path="/rh/*" element={<RhRoutes />} />
      <Route path="/sign/*" element={<SignRoutes />} />
    </Route>
  </Routes>
);

const ClientRoutes = (
  <Routes>
    <Route element={<Layout />}>
      <Route
        path="/financial"
        element={<FinancialIndex isBackOffice={false} />}
      />
      ;
      <Route
        path="/Documents/*"
        element={<DocumentsRoutes isBackOffice={false} />}
      />
      ;
      <Route
        path="/business/*"
        element={<BusinessRoutes isBackOffice={false} />}
      />
      <Route
        path="/demands/*"
        element={<DemandsRoutes isBackOffice={false} />}
      />
      <Route
        path="/journeys/*"
        element={<JourneyRoutes isBackOffice={false} />}
      />
      <Route path="/tools/*" element={<ToolsRoutes isBackOffice={false} />} />
      <Route path="/demand/*" element={<DemandRoutes isBackOffice={false} />} />
      <Route
        path="/profiles/*"
        element={<ProfilesRoutes isBackOffice={false} />}
      />
      <Route path="/air/*" element={<AIRRoutes isBackOffice={false} />} />
      <Route path="/politicas/*" element={<PoliticsRoutes />} />
      <Route path="/*" element={<CustomerIndex />} />;
      <Route path="/rh/*" element={<RhRoutes />} />
      <Route path="/sign/*" element={<SignRoutes />} />
    </Route>
  </Routes>
);

export default function PrivateRoutes() {
  const [userType, setUserType] = React.useState('');

  useEffect(() => {
    userProvider.userLogged().then((response) => {
      setUserType(response.type);
    });
  }, []);

  let RoutesToUse = null;

  switch (userType) {
    case 'CUSTOMER': {
      RoutesToUse = ClientRoutes;
      break;
    }
    case 'BACKOFFICE': {
      RoutesToUse = BackofficeRoutes;
      break;
    }
    default: {
      return null;
    }
  }

  return RoutesToUse;
}
