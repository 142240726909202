/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import signProvider from '../../providers/sign';

function UserSignAuth() {
  const navigate = useNavigate();
  const { token } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchPDFUrl = async () => {
      const userToken = await signProvider.getDecodedToken({
        token,
      });

      if (userToken) {
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: userToken.url },
        });
        navigate(
          `/signAuth/onlysigning/${userToken.documentId}/${userToken.signatoryId}/${userToken.docSignatureId}/${token}`,
        );
      } else {
        toast.error('Este Documento nao pertence a este usuario');
        dispatch({
          type: 'SET_FILE_URL',
          payload: { fileURL: null },
        });
        navigate(`/signAuth/signing/${userToken.documentId}`);
      }
    };
    if (token) {
      fetchPDFUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Row className="justify-content-center">
      <Row className="" />
    </Row>
  );
}

export default UserSignAuth;
