import React from 'react';
import { HiOutlineIdentification } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import ModalForm from '../../../components/Modals/ModalForm';
import schemaAddUserToCustomer from '../../../validators/schemas/customers/addUserToCustomer';
import InputSelect from '../../../components/Inputs/Input_Select';
import customerProvider from '../../../providers/customer';
import InputMask from '../../../components/Inputs/Input_Mask';

export default function ModalAddUserToCustomer() {
  const customer = useSelector((state) => state.customer);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const onSubmit = async (data) => {
    const newUser = await customerProvider.addUserToBusinessCustomer(
      customer.id,
      data.businessId,
      data,
    );

    if (newUser.code === 'ERR_BAD_REQUEST') {
      return;
    }

    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        ...customer,
        users: [...customer.users, newUser],
      },
    });
    resetModal();
  };
  const optionsBusiness = customer?.business?.map((business) => ({
    label: business.name,
    value: business.id,
  }));
  return (
    <ModalForm
      open={modal?.modalAddUserToCustomer?.isOpen}
      close={resetModal}
      schema={schemaAddUserToCustomer}
      onSubmit={onSubmit}
      style={{ width: '30%' }}
    >
      <h3 className="text-center">Adicionar Usuário</h3>

      <InputGeneric name="name" label="Nome*" icon={HiOutlineIdentification} />
      <InputGeneric
        name="email"
        label="E-mail*"
        icon={HiOutlineIdentification}
      />
      <InputGeneric
        name="occupation"
        label="Cargo/Função"
        icon={HiOutlineIdentification}
      />
      <InputGeneric
        name="countryCode"
        label="Código do país"
        icon={HiOutlineIdentification}
        defaultValue="55"
      />
      <InputMask
        type="phone"
        name="phone"
        label="Telefone"
        icon={HiOutlineIdentification}
      />
      <InputSelect
        name="businessId"
        label="Negócio"
        placeholder="Selecione um negócio"
        isMulti
        options={optionsBusiness}
      />
      <div
        className="mt-3"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <InputCheckbox
          className="me-4"
          name="customerSuperAdmin"
          label="Super Administrador"
        />
        <InputCheckbox
          className="me-4"
          name="customerAdmin"
          label="Administrador"
        />
        <InputCheckbox name="customerIsFinance" label="Financeiro" />
      </div>
    </ModalForm>
  );
}
