import React, { useEffect, useRef, useState } from 'react';
import { BiSend } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import FormGeneric from '../../Form';
import RichTextArea from '../../Inputs/Input_RichTextArea';
import ButtonGeneric from '../../Button';
import MessagesChat from '../../MessagesChat';
import NewActiviteContactProvider from '../../../providers/NewActiveContacts';
import InputRadio from '../../Inputs/Input_Radio';
import s3 from '../../../providers/urlGenerator';

export default function ModalDigisac() {
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const customerId = queryParam.get('customerId');
  const newActiveContactId = queryParam.get('newActiveContactId');
  const userId = queryParam.get('userId');
  const navigate = useNavigate();
  const [clearRichText, setClearRichText] = useState();

  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [chatType, setChatType] = useState('DIGISAC');
  const messageBox = useSelector((state) => state.messageBox);
  const dispatch = useDispatch();

  const scrollRef = useRef(null);

  const closeModal = () => {
    navigate(location.pathname);
  };

  const submitDigisacMessage = async (e) => {
    const { message } = e.message;

    const user = users.find((u) => u.user.id === userId);

    if (user?.user?.phone === '' || !user?.user?.phone) {
      toast.error('Usuário sem telefone cadastrado');
      return;
    }

    await NewActiviteContactProvider.sendDigisacMessage({
      newActiveContactId,
      userId,
      message,
    });
    await clearRichText();
    fetch();
  };
  const submitEmailMessage = async (e) => {
    const { message } = e.message;

    const user = users.find((u) => u.user.id === userId);

    if (user?.user?.email === '' || !user?.user?.email) {
      toast.error('Usuário sem email cadastrado');
      return;
    }

    await NewActiviteContactProvider.sendEmailMessage({
      newActiveContactId,
      userId,
      message,
      attachments: e.attachments,
    });
    await clearRichText();
    fetch();
  };

  const handleSubmit = async (e) => {
    if (e.message.message.trim() === '') {
      toast.error('Mensagem não pode ser vazia');
      return;
    }

    const attachments = await Promise.all(
      e?.message.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }) || [],
    );

    if (chatType === 'DIGISAC') {
      submitDigisacMessage(e);
      return;
    }

    submitEmailMessage({
      ...e,
      attachments,
    });
  };

  const fetchUsers = async () => {
    const response =
      await NewActiviteContactProvider.usersLinkedToActiveContact({
        newActiveContactId,
      });
    if (response.length === 0) {
      toast.error('Não há usuários vinculados a esse contato ativo');
    }
    setUsers(response);
  };

  useEffect(() => {
    if (messageBox?.userId === userId && messageBox?.userId) {
      fetch();
      dispatch({
        type: 'RESET_MESSAGE_BOX_REDUCER',
      });
    }

    return () => {
      setMessages([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageBox?.userId, userId]);

  useEffect(() => {
    if (newActiveContactId) {
      dispatch({
        type: 'RESET_MESSAGE_BOX_REDUCER',
      });
      fetchUsers();
    }

    // eslint-disable-next-line
  }, [newActiveContactId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  const fetch = async () => {
    const response = await NewActiviteContactProvider.gettingChatBoxMessages({
      newActiveContactId,
      userId,
    });

    const formatData = response
      .filter((res) => res.messageFrom === chatType)
      .map((item) => {
        const isBackoffice = item.userType === 'BACKOFFICE';
        const isDigisac = item.messageFrom === 'DIGISAC';
        const isEmail = item.messageFrom === 'EMAIL';
        let color = null;

        if (!isBackoffice && isDigisac) {
          color = '#90ee90';
        }

        if (!isBackoffice && isEmail) {
          color = '#ADD8E6';
        }

        return {
          content: item.message,
          createdAt: item.createdAt,
          id: item.id,
          userId: item.userId,
          userName: `${item.userName} (${item.messageFrom})`,
          userType: isBackoffice ? 'BACKOFFICE' : 'CUSTOMER',
          colorCard: color,
        };
      });

    setMessages(formatData);
  };

  const focused =
    'd-inline-flex focus-ring py-1 px-2 text-decoration-none border rounded-2" style="--bs-focus-ring-color: rgba(var(--bs-success-rgb), .25)';

  useEffect(() => {
    if (userId) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, chatType]);

  return (
    <ModalGeneric
      show={customerId}
      close={closeModal}
      style={{
        overflow: 'scroll',
      }}
    >
      <div className="d-flex flex-column">
        <div
          style={{ height: '400px' }}
          className="overflow-scroll"
          ref={scrollRef}
        >
          <MessagesChat messages={messages} />
        </div>

        <FormGeneric
          onSubmit={handleSubmit}
          style={{
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '5px',
              paddingTop: '0',
              height: '100%',
            }}
          >
            <RichTextArea
              keyId={`d${customerId}`}
              saveToType="sendMessage"
              name="message"
              setClearRichText={setClearRichText}
              onlyAtachFiles
            />
            <ButtonGeneric
              type="submit"
              className="d-flex justify-content-center align-items-center border-0"
              style={{
                borderRadius: '0',
                width: '45px',
                height: '100%',
                padding: '0',
                margin: '0',
              }}
            >
              <BiSend size={25} color="white" />
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </div>
      <InputRadio
        options={[
          {
            value: 'DIGISAC',
            label: 'Digisac',
          },
          {
            value: 'EMAIL',
            label: 'Email',
          },
        ]}
        name="personType"
        label="Tipos de Envio"
        defaultValue={chatType}
        onChange={(e) => {
          setChatType(e.id.split('_')[1]);
        }}
        register={() => {}}
      />
      <h4 className="text-center">Usuários Vinculados ao contato ativo</h4>
      <div className="d-flex flex-wrap justify-content-center gap-2 mt-2">
        {users.map((user) => (
          <Link
            to={`/cs/activeContact/new?customerId=${customerId}&newActiveContactId=${newActiveContactId}&userId=${user.user.id}`}
            className={
              userId === user.user.id
                ? `${focused} bg-success text-white`
                : 'd-inline-flex py-1 px-2 text-decoration-none border rounded-2'
            }
          >
            {user.user.name}
          </Link>
        ))}
      </div>
    </ModalGeneric>
  );
}
