/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ButtonGeneric from '../../components/Button';
import DateUtils from '../../utils/DateUtils';

const signedColumns = (downloadDocs, getHash) => [
  {
    name: 'Nome',
    selector: (row) => row?.Document?.fileName,
    sortable: true,
    cell: (row) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <span>{row?.doc[0]?.document?.fileName}</span>
    ),
  },
  {
    name: 'Status',
    sortable: true,
    cell: (row) => (
      <Row className=" aligm-itens-center justify-content-center text-center ">
        <Col xl={12} className="mt-4">
          <span
            className=""
            style={{
              borderRadius: '40px',
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          >
            Assinado
          </span>
          <span className="text-center" style={{ marginLeft: '8px' }}>
            ✅
          </span>
        </Col>
        <Col sm={12} className="mt-3 d-flex flex-column">
          <span
            style={{
              fontSize: '15px',
            }}
            className="mt-2"
          >
            Criado em:{' '}
            {DateUtils.formatToLocaleWithHour(row?.doc[0]?.createdAt)}
          </span>
          <span
            style={{
              fontSize: '15px',
            }}
            className="mt-2 mb-2"
          >
            Assinado em: {row?.lastSignatory}
          </span>
        </Col>
      </Row>
    ),
  },
  {
    name: 'Ações',
    sortable: true,
    cell: (row) => (
      <div className=" text-center">
        <ButtonGeneric
          onClick={() => downloadDocs(row)}
          className="text-center text-white"
          variant="primary"
        >
          Baixar PDF
        </ButtonGeneric>
        <ButtonGeneric
          onClick={() => getHash(row)}
          className="text-center text-white ms-2"
          variant="primary"
        >
          Ver Assinaturas
        </ButtonGeneric>
      </div>
    ),
  },
];

export default signedColumns;
