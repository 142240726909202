import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { MdSpeakerNotes } from 'react-icons/md';
import ButtonGeneric from '../../../components/Button';
import OnboardingService from '../../../providers/onboarding';
import customerProvider from '../../../providers/customer';
import setStoreRedux from '../../../utils/setStore';

const getDifferenceInBusinessDays = (firstDate, secondDate) => {
  const Sunday = 0;
  const Saturday = 6;
  let diff = 0;

  const isSecondDateAfterFirstDate = secondDate.isAfter(firstDate, 'day');
  let currentDate = isSecondDateAfterFirstDate ? firstDate : secondDate;
  const targetDate = isSecondDateAfterFirstDate ? secondDate : firstDate;

  while (!currentDate.isSame(targetDate, 'day')) {
    if (currentDate.day() !== Sunday && currentDate.day() !== Saturday) {
      diff += isSecondDateAfterFirstDate ? 1 : -1;
    }
    currentDate = isSecondDateAfterFirstDate
      ? currentDate.add(1, 'day')
      : currentDate.subtract(1, 'day');
  }

  return diff;
};

export const compareDates = (dateA, dateB) => {
  const momentA = dayjs(dateA, 'DD/MM/YYYY');
  const momentB = dayjs(dateB, 'DD/MM/YYYY');

  if (momentA.isValid() && momentB.isValid()) {
    return momentA.diff(momentB, 'days');
  }

  return 0;
};

const updateCustomerOnboardingStatus = async (id, data, fetch) => {
  await OnboardingService.updateCustomerOnboardingStatus(id, data);
  await fetch();
};

const setCustomerOnboardingMeetingSuggestedAt = async (id, fetch) => {
  await OnboardingService.setCustomerOnboardingMeetingSuggestedAt(id);
  await fetch();
};

const handleModalNotes = async (data) => {
  const getCustomer = await customerProvider.getById(
    data.customer.customerValue.id,
  );

  setStoreRedux('SET_CUSTOMER', {
    ...getCustomer,
  });
  setStoreRedux('SET_MODAL_STATE', {
    modalAnnotations: {
      isOpen: true,
    },
  });
};

const commonColumns = [
  {
    name: 'Cliente',
    selector: (row) => (
      <Link className="text-decoration-none text-dark">
        <Link
          className="text-decoration-none text-dark"
          to={`/customers/${row?.customer?.id}`}
        >
          {row?.customer?.isSpecificDemand
            ? `Demanda específica - ${row?.customer?.name}`
            : row?.customer?.name}
        </Link>
      </Link>
    ),
    sortFunction: (a, b) => {
      const aValue = a.customer.name;
      const bValue = b.customer.name;
      return aValue.localeCompare(bValue);
    },
    sortable: true,
    width: '45%',
  },
];

const columnsWaitingOnboarding = (fetch) => [
  ...commonColumns,
  {
    name: 'Data da venda',
    selector: (row) =>
      row.saleDate ? dayjs(row.saleDate).format('DD/MM/YYYY') : 'Indefinido',
    sortFunction: (a, b) => compareDates(a.saleDate, b.saleDate),
    sortable: true,
    center: true,
  },
  {
    name: 'Reunião sugerida em',
    selector: (row) => {
      return row.SuggestedMeetingIn ? (
        <span>{dayjs(row?.SuggestedMeetingIn).format('DD/MM/YYYY')}</span>
      ) : (
        <ButtonGeneric
          size="sm"
          variant="info"
          type="button"
          className="text-white"
          onClick={() =>
            setCustomerOnboardingMeetingSuggestedAt(row.customer.id, fetch)
          }
        >
          Sugerir Reunião
        </ButtonGeneric>
      );
    },
    sortFunction: (a, b) =>
      compareDates(a.SuggestedMeetingIn, b.SuggestedMeetingIn),
    sortable: true,
    center: true,
  },
  {
    name: 'Reunião realizada',
    selector: (row) =>
      row.meetingHeld ? (
        <ButtonGeneric
          size="sm"
          variant="success"
          type="button"
          className="text-white"
          onClick={() =>
            updateCustomerOnboardingStatus(
              row.customer.id,
              {
                status: 'ON_ONBOARDING',
              },
              fetch,
            )
          }
        >
          Registrar Reunião
        </ButtonGeneric>
      ) : (
        <span className="text-danger fw-bold">Sugira a reunião antes</span>
      ),
    sortable: false,
    center: true,
  },
];

const columnsInOnboarding = (fetch) => [
  {
    name: 'Cliente',
    selector: (row) => {
      const isDelayed =
        !!row.customer.onboardingMeetingFinishedAt &&
        getDifferenceInBusinessDays(
          dayjs(row.customer.onboardingMeetingFinishedAt),
          dayjs(),
        ) > 20;
      const customerName = row.customer.customerValue?.isSpecificDemand
        ? `Demanda específica - ${row.customer.customerValue?.name}`
        : row.customer.customerValue?.name;

      return (
        <>
          <Link
            className={`text-decoration-none ${
              isDelayed ? 'text-danger text-bold' : 'text-dark'
            }`}
            to={`/customers/${row.customer.customerValue?.id}`}
          >
            {customerName}
          </Link>
          <Link
            className={`text-decoration-none ${
              isDelayed ? 'text-danger text-bold' : 'text-dark'
            }`}
          >
            <MdSpeakerNotes
              className="ms-3"
              onClick={() => {
                handleModalNotes(row);
              }}
            />
          </Link>
        </>
      );
    },
    sortFunction: (a, b) => {
      const aValue = a.customer.customerValue.name || '';
      const bValue = b.customer.customerValue.name || '';
      return aValue.localeCompare(bValue);
    },
    sortable: true,
    width: '45%',
  },
  {
    name: 'Ativar Cliente',
    selector: (row) => (
      <ButtonGeneric
        size="sm"
        className="text-white"
        onClick={() => {
          updateCustomerOnboardingStatus(
            row.customer.customerValue.id,
            {
              status: 'ONBOARDING_FINISHED',
            },
            fetch,
          );
        }}
      >
        Ativar Cliente
      </ButtonGeneric>
    ),
    sortable: false,
    center: true,
  },
  {
    name: 'Aguardando Ativação (dias úteis)',
    selector: (row) => (
      <div>
        <p
          className={
            !!row?.waitingActivation &&
            getDifferenceInBusinessDays(
              dayjs(row?.waitingActivation),
              dayjs(),
            ) > 20
              ? 'text-danger text-bold'
              : 'text-dark'
          }
        >
          {row?.waitingActivation
            ? getDifferenceInBusinessDays(
                dayjs(row?.waitingActivation),
                dayjs(),
              )
            : 'Indefinido'}
        </p>
      </div>
    ),
    sortFunction: (a, b) => {
      const aValue =
        getDifferenceInBusinessDays(dayjs(a.waitingActivation), dayjs()) || 0;
      const bValue =
        getDifferenceInBusinessDays(dayjs(b.waitingActivation), dayjs()) || 0;
      return aValue - bValue;
    },
    sortable: true,
    center: true,
  },
];

const columnsMetric = [
  ...commonColumns,
  {
    name: 'Data da venda',
    selector: (row) =>
      row?.saleDate ? dayjs(row?.saleDate).format('DD/MM/YYYY') : 'Indefinido',
    sortFunction: (a, b) => compareDates(a.saleDate, b.saleDate),
    sortable: true,
  },
  {
    name: 'Reunião sugerida em',
    selector: (row) =>
      row.SuggestedMeetingIn ? (
        <span>{dayjs(row.SuggestedMeetingIn).format('DD/MM/YYYY')}</span>
      ) : (
        <span className="text-danger">Indefinido</span>
      ),
    sortFunction: (a, b) => {
      const aValue = a.SuggestedMeetingIn || '01/01/2000';
      const bValue = b.SuggestedMeetingIn || '01/01/2000';
      return compareDates(aValue, bValue);
    },
    sortable: true,
  },
];

export { columnsWaitingOnboarding, columnsInOnboarding, columnsMetric };
