import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignIndex from '../../pages/Sign';
import SignPage from '../../pages/Sign/SignPage';
import OnlySigningCustomer from '../../pages/Sign/OnlySigningCustomer';

export default function SignRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SignIndex />} />
      <Route
        path="/signPDFPage/:id/:signId/:docSignatureId"
        element={<SignPage />}
      />
      <Route
        path="/onlysigning/:id/:signId/:docSignatureId/"
        element={<OnlySigningCustomer />}
      />
      <Route path="/signPDFPage/:key/:token" element={<SignPage />} />
      <Route path="/signPDFPage/auth" element={<SignPage />} />
    </Routes>
  );
}
