/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DateUtils from '../../utils/DateUtils';

const pendingColumns = (siningPendingDocs) => [
  {
    name: 'Nome',
    selector: (row) => row?.Document?.fileName,
    sortable: true,
    cell: (row) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <span>{row?.doc?.fileName}</span>
    ),
  },
  {
    name: 'Assinaturas',
    selector: (row) => row?.Document?.fileName,
    sortable: true,
    cell: (row) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <ul
        className="w-100 mt-3"
        style={{ padding: '0', listStyle: 'none', margin: '0' }}
      >
        {row?.signatureHistory?.map((signatory) => {
          return (
            <li
              className="d-flex flex-wrap align-items-center mb-3"
              style={{ gap: '5px' }} // Adiciona espaço entre os elementos
            >
              <p className="ms-3 mb-0" style={{ flex: '0 0 auto' }}>
                {signatory?.signedAt === null ? (
                  <span style={{ marginLeft: '2px', fontSize: '12px' }}>
                    ⚠️
                  </span>
                ) : (
                  <span style={{ marginLeft: '8px' }}>✅</span>
                )}
              </p>
              <p className="me-2 mb-0" style={{ flex: '1 1 auto' }}>
                {signatory?.name}
              </p>

              <p className="mb-0" style={{ flex: '2 1 auto' }}>
                {signatory?.email}
              </p>
            </li>
          );
        })}
      </ul>
    ),
  },
  {
    name: 'Status',
    cell: (row) => (
      <Row className=" aligm-itens-center justify-content-center text-center m-0 mt-3">
        <Col sm={12} className="">
          <span
            className=""
            style={{
              borderRadius: '40px',
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          >
            Aguardando Assinatura
          </span>
          <span style={{ marginLeft: '8px' }}>⚠️</span>
        </Col>
        <Col sm={12} className="mt-3 d-flex flex-column">
          <span
            style={{
              fontSize: '15px',
            }}
            className="mt-2"
          >
            Criado em: {DateUtils.formatToLocaleWithHour(row?.doc?.createdAt)}
          </span>
        </Col>
      </Row>
    ),
  },
];

export default pendingColumns;
