import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ModalForm from '../../../components/Modals/ModalForm';
import customerProvider from '../../../providers/customer';
import s3 from '../../../providers/urlGenerator';
import FileInput from '../../../components/Inputs/Input_File';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';

export default function ModalSendEmailContract() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const [relatory, setRelatory] = React.useState(null);
  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
    setRelatory(null);
  };

  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.formatFileToBase64(file);
        return uploaders;
      }),
    );
  };

  const onSubmit = async (data) => {
    const fileSale = await handleFiles(data.attachments);
    const request = {
      attachments: fileSale,
      isToRemoveText: data.isToRemoveText,
      numberOfDemands: data.numberOfDemands,
      aproximateTime: data.aproximateTime,
      pricePaidPerDemand: data.pricePaidPerDemand,
    };

    if (!relatory) {
      request.getReport = true;
    }

    if (data.emails) {
      request.emails = data.emails.split(',').map((email) => {
        return email.trim();
      });
    }
    try {
      const response = await customerProvider.sendCustomerContractRenewalEmail(
        customer.id,
        request,
      );
      if (request.getReport) {
        setRelatory({
          ...response,
          pricePaidPerDemand: response.pricePaidPerDemand || 0,
        });
        return;
      }

      toast.success('Email enviado com sucesso!');
    } catch (error) {
      toast.error('Erro ao enviar email!');
    }
    const getCustomer = await customerProvider.getById(customer.id);
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        ...getCustomer,
      },
    });
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalSendEmailContract?.isOpen}
      close={resetModal}
      schema={schemaGeneric}
      onSubmit={onSubmit}
      style={{
        width: '32%',
      }}
      confirmationButtonText={!relatory ? 'Gerar relatorio' : 'Enviar email'}
    >
      <h4 className="text-center">Enviar email de renovação de contrato</h4>
      <p>
        O email será enviado para:{' '}
        <a href={`mailto:${customer?.email}}`}>{customer?.email}</a>
      </p>
      <InputGeneric
        name="emails"
        label='Email-s adicionais, separados por vírgula (,) ex: "a@email.com, b@email.com"'
        placeholder="Email-s adicionais"
      />
      {relatory && (
        <>
          <InputGeneric
            name="numberOfDemands"
            label="Número de demandas"
            defaultValue={relatory.numberOfDemands}
          />

          <InputGeneric
            name="aproximateTime"
            label="Tempo gasto"
            defaultValue={relatory.aproximateTime}
          />
          <InputGeneric
            name="pricePaidPerDemand"
            label="Preço pago por demanda"
            defaultValue={relatory.pricePaidPerDemand.replace('.', ',')}
          />
        </>
      )}
      <FileInput name="attachments" label="Anexos" />
      <InputCheckbox
        label="Tirar apenas e tudo do email"
        name="isToRemoveText"
        className="mt-2"
      />
    </ModalForm>
  );
}
