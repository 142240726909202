/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';

import { AiOutlineUsergroupAdd, AiOutlineMail } from 'react-icons/ai';
import { PiOfficeChair } from 'react-icons/pi';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import InputGeneric from '../../components/Inputs/Input_generic';
import FormGeneric from '../../components/Form';
import InputCheckbox from '../../components/Inputs/Input_Checkbox';
import ButtonGeneric from '../../components/Button';

import { schemaEditUserBackoffice } from '../../validators/schemas/users/createUserBackoffice';
import InputMask from '../../components/Inputs/Input_Mask';
import ModalDeleteUser from './ModalDeleteUser';
import userProvider from '../../providers/user';

export default function UserByIdCustomer() {
  const dispatch = useDispatch();
  const [userSelected, setUserSelected] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const request = {
      email: data.email || userSelected?.email,
      name: data.name || userSelected?.name,
      occupation: data.occupation || userSelected?.customFields?.occupation,
      phone: data.phone || userSelected?.phone,
      customerAdmin: data.customerAdmin,
      customerSuperAdmin: data.customerSuperAdmin,
      customerIsFinance: data.customerIsFinance,
      countryCode: data.countryCode || userSelected?.countryCode,
    };
    try {
      await userProvider.editUser(userSelected?.id, request);
      await fetch();
      toast.success('Usuário editado com sucesso!');
    } catch (error) {
      toast.success('Erro ao editar usuário!');
    }
  };

  const fetch = async () => {
    try {
      const getUrl = window.location.href.split('/');
      const id = getUrl[getUrl.length - 1];
      const user = await userProvider.getById(id);

      setUserSelected(user);
    } catch (e) {
      navigate('/users/backoffice/');
    }
  };

  const openUp = () => {
    dispatch({ type: 'OPEN_MODAL', payload: { id: userSelected?.id } });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userSelected) return null;

  return (
    <>
      <TitlePage
        title="Informações do Usuário Customer"
        item="Usuários"
        description="Nesta página, você pode visualizar e gerenciar as informações detalhadas de usuário customer. Tenha acesso a dados importantes como permissões, atividades recentes e informações de contato"
      />
      <MainPage>
        <div className="pt-3 d-flex justify-content-end">
          <ButtonGeneric
            onClick={() => openUp()}
            variant="danger"
            type="button"
          >
            Excluir Usuário
          </ButtonGeneric>
        </div>
        <ModalDeleteUser
          customerId={userSelected.customerId}
          finallyFunc={() => navigate('/users/backoffice/')}
        />
        <FormGeneric onSubmit={handleSubmit} schema={schemaEditUserBackoffice}>
          <InputGeneric
            name="name"
            placeholder="Digite o nome"
            label="Nome*"
            icon={AiOutlineUsergroupAdd}
            defaultValue={userSelected?.name}
          />
          <InputGeneric
            name="email"
            placeholder="Digite o email"
            label="Email*"
            icon={AiOutlineMail}
            defaultValue={userSelected?.email}
          />
          <InputGeneric
            name="occupation"
            placeholder="Digite o título (Função, cargo, etc...)"
            label="Título*"
            icon={PiOfficeChair}
            defaultValue={userSelected?.customFields?.occupation}
          />
          <InputGeneric
            name="countryCode"
            placeholder="Digite o código do país"
            label="Código do País"
            defaultValue={userSelected?.countryCode}
          />
          <InputMask
            type="phone"
            name="phone"
            placeholder="Digite o telefone"
            label="Telefone"
            icon={AiOutlineUsergroupAdd}
            defaultValue={userSelected?.phone}
          />
          <InputCheckbox
            name="customerSuperAdmin"
            label="Super Administrador"
            defaultValue={userSelected?.customerSuperAdmin}
          />
          <InputCheckbox
            name="customerAdmin"
            label="Administrador"
            defaultValue={userSelected?.customerAdmin}
          />
          <InputCheckbox
            name="customerIsFinance"
            label="Financeiro"
            defaultValue={userSelected?.customerIsFinance}
          />
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric variant="dark" type="submit">
              Salvar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
