import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function SignIcon({ width = 200, height = 200, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="325 300 550 550" // Focado na área onde o ícone está
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      {/* Parte do contorno preto */}
      <path
        transform="translate(650,326)"
        d="m0 0 9 2 8 7 6 9 3 10v7l-11 12-9 11-11 12-9 11-11 12-6 8 44 29 23 15 10 4 5 1h10l9-3 3-3 1-2h69l7 10 9 19 5 15 4 18 2 16v39l-3 30-6 29-9 27h-77v-32l-25 7-17 3-17 1-27-2-27-3h-30l-63 6h-51l-11-3-10-6-7-8-9 9-7 8-9 10-5 4-41 18-35 15-1-3 12-24 11-23 14-29v-26l6-23 21-70 8-21 7-12 12-14 7-8 12-13 8-7 10-9 17-13 15-10 16-8 12-5 10-2h13l10 3 11 6 7 5 4-2 14-15 7-8 18-20 7-8 7-6 10-5z"
        fill="#000"
      />
      {/* Parte da mão branca */}
      <path
        transform="translate(606,440)"
        d="m0 0 4 1 16 10 27 18 32 20 9 2h23l7-1 4 9 6 19 4 19 1 7v34l-3 17-6 20-4 8-29 7-16 3-16 1-26-2-30-3h-25l-21 2-22 3-12 1h-30l4-12 1-6v-19l-3-12-6-11 37-1 20-2 19-4 16-6 8-5 1-2v-8l-2-5-23 9-17 4h-7l6-8 7-11 6-15 4-18 2-24 12-13 9-11 10-11z"
        fill="#fff"
      />
      {/* Parte de detalhes adicionais */}
      <path
        transform="translate(532,393)"
        d="m0 0h10l8 3 11 9v3l-11 12-7 8-63 70-7 8-11 12-4 11-8 26-6 14-7 10-15 15-11 7-4 1 7-11 4-16v-16l-5-12-7-11-1-4 9-29 8-18 9-14 11-12 13-12 7-7 8-7 14-12 16-12 18-11 10-4z"
        fill="#fff"
      />
    </BaseSvgIcon>
  );
}

export default SignIcon;
