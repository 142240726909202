import { store } from '..';
import customerProvider from '../../providers/customer';
import userProvider from '../../providers/user';
import { searchBackofficeParams } from '../../utils/params';

const fetchById = async (id) => {
  const getCustomer = await customerProvider.getById(id);
  const businessDefault = await customerProvider.getBusinessDefault(
    getCustomer?.id,
  );
  const customerSales = await customerProvider.getSaleCustomer(id);

  const renderContractType = (contractType) => {
    if (contractType === 'LEGAL_ADVICE') {
      return 'Assessoria Jurídica';
    }
    if (contractType === 'SPECIFIC_DEMAND') {
      return 'Demanda Específica';
    }
    return 'Indefinido';
  };

  store.dispatch({
    type: 'SET_CUSTOMER',
    payload: {
      ...businessDefault,
      ...getCustomer,
      contractType: renderContractType(customerSales?.contractType),
      sale: customerSales,
    },
  });
  const usersBackoffice = await userProvider.getAll(searchBackofficeParams);
  store.dispatch({
    type: 'SET_BACKOFFICE_STATE',
    payload: {
      rows: usersBackoffice.rows,
    },
  });
};

const customersActions = {
  fetchById,
};

export default customersActions;
