/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BiPencil } from 'react-icons/bi';
import DemandIcon from './customIcons/DemandIcon';
import DEMAND_CONSTANTS from '../constants/demands';
import demandsProvider from '../providers/demands';
import Table from './Table/Table';
import DateUtils from '../utils/DateUtils';
import ButtonGeneric from './Button';
import SmartLink from './SmartLink';
import PrivateDemandIcon from './customIcons/PrivateDemandIcon';
import PrioritizedDemandIcon from './customIcons/PrioritizedDemandIcon';
import ValidateDemandIcon from './customIcons/ValidateDemandIcon';
import ModalPrivateDemand from './Modals/ModalDemand/ModalPrivateDemand';
import genericDataTableActions from '../store/actions/genericDataTableActions';
import ModalConfirmation from './Modals/ModalConfirmation';
import ModalReorderDemand from './Modals/ModalDemand/ModalReorderDemand';
import DemandCellNumber from './DemandCellNumber';
import RoundedPill from './RoundedPill';
import DemandStatusSelector from './DemandStatusSelector';
import demandListActions from '../store/actions/demandsListActions';

const columnsDemands = [
  {
    name: 'Nº',
    compact: true,
    width: '40px',
    sortable: true,
    columnName: 'externalId',
    selector: (row) => row.externalId,
    cell: (row) => <DemandCellNumber demand={row} />,
  },
  {
    name: 'Nome',
    columnName: 'name',
    grow: 1,
    selector: (row) => row?.name,
    sortable: true,
  },
  {
    name: 'Atualização',
    compact: true,
    grow: 0,
    columnName: 'lastBackofficeAction',
    selector: (row) => DateUtils.formatToLocale(row.lastBackofficeAction),
    sortable: true,
  },
  {
    name: 'Previsão',
    compact: true,
    grow: 0,
    columnName: 'deleveryForecast',
    selector: (row) => DateUtils.formatToLocale(row.deleveryForecast),
    sortable: true,
  },
  {
    name: 'Ações',
    width: '160px',
    columnName: 'actions',
    cell: (row) => (
      <div>
        <SmartLink target="_blank" to={`/demand/${row.id}`}>
          <ButtonGeneric
            variant="link"
            className="ps-0 border-0 text-decoration-none text-black btn-sm align-items-center d-flex"
          >
            {row.priority && (
              <PrioritizedDemandIcon
                fill="#7F8896"
                padded={false}
                width={20}
                height={20}
              />
            )}
            <PrivateDemandIcon
              fill="#7F8896"
              padded={false}
              width={20}
              height={20}
              striked={!!row.private}
            />
            Ver detalhes
          </ButtonGeneric>
        </SmartLink>
      </div>
    ),
  },
];

const demandsActions = genericDataTableActions.generateGenericActions({
  dispatchString: 'DEMANDS_LIST',
  fetchFunction: demandsProvider.getDemands,
  listReducerKey: 'demandsList',
});

export default function DemandsList() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [showModalPrivateDemand, setShowModalPrivateDemand] = useState(false);
  const [showModalValidateDemand, setShowModalValidateDemand] = useState(false);
  const [showModalReorderDemand, setShowModalReorderDemand] = useState(false);

  const authUser = useSelector((state) => state.auth.auth);
  const demandsList = useSelector((state) => state.demandsList);
  const { statusSelected, validatedSelected } = useSelector(
    (state) => state.demandsList,
  );
  const { setDemandCount, setValidatedSelected } = demandListActions;
  columnsDemands[1].cell = (row) => {
    if (row?.visualized?.includes(authUser.id)) {
      return row.name;
    }
    return (
      <strong onClick={() => window.open(`/#/demand/${row.id}`, '_blank')}>
        {row.name}
      </strong>
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setTimeout(
        () =>
          dispatch({
            type: 'RESET_DEMAND_LIST',
          }),
        300,
      );
    };
  }, []);

  useEffect(() => {
    if (statusSelected === 'ALL') {
      const keysInserted = ['validationDate', 'statusKey'];

      const cleanedWhere =
        demandsList.where.filter &&
        demandsList.where.filter((e) => !keysInserted.includes(e.key));

      dispatch({
        type: 'SET_DEMANDS_LIST',
        payload: {
          where: cleanedWhere || [],
          order: [['lastBackofficeAction', 'desc']],
        },
      });
    } else {
      let whereToApply = [{ key: 'statusKey', value: statusSelected }];

      if (statusSelected === 'ALL') {
        whereToApply = [];
      }

      if (statusSelected === 'FINISHED') {
        dispatch({
          type: 'SET_DEMANDS_LIST',
          payload: {
            order: [['lastBackofficeAction', 'desc']],
          },
        });

        whereToApply.push({
          key: 'validationDate',
          operator: !validatedSelected ? 'is' : 'not',
          value: 'null',
        });
      }

      if (statusSelected !== 'FINISHED') {
        whereToApply = whereToApply.filter((e) => e.key !== 'validationDate');
        dispatch({
          type: 'SET_DEMANDS_LIST',
          payload: {
            where: whereToApply,
          },
        });
        demandsList.where = whereToApply;
      }

      const demandListWhere = [...demandsList.where];
      const whereMerged = [...whereToApply];
      demandListWhere.forEach((e) => {
        const index = whereMerged.findIndex((i) => i.key === e.key);
        if (index === -1) {
          whereMerged.push(e);
        }
      });

      dispatch({
        type: 'SET_DEMANDS_LIST',
        payload: { where: whereMerged },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSelected, validatedSelected]);

  useEffect(() => {
    DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS.map((status) => {
      const where =
        (demandsList.where.filter &&
          demandsList.where.filter(
            (e) => e.key !== 'statusKey' && e.key !== 'validationDate',
          )) ||
        [];

      if (status.key !== 'ALL') {
        where.push({ key: 'statusKey', value: status.key });
      }

      return demandsProvider
        .getDemands({
          limit: 1,
          where,
        })
        .then((response) => {
          setDemandCount({
            [status.key]: response.count,
          });
        });
    });
  }, [demandsList.where]);

  let showValidadeDemand = false;
  if (selectedRows.length > 0) {
    selectedRows.forEach((row) => {
      if (row.statusKey === 'FINISHED' && row.validationDate === null) {
        showValidadeDemand = true;
      }
    });
  }
  const contextActions = (
    <>
      {selectedRows.find((row) => !row.private) && (
        <ButtonGeneric
          style={{ color: '#C08C00' }}
          variant="link"
          className="text-decoration-none align-items-center d-flex "
          onClick={() => setShowModalPrivateDemand(true)}
        >
          <PrivateDemandIcon
            fill="#C08C00"
            padded={false}
            width={20}
            height={20}
          />
          Tornar sigilosa
        </ButtonGeneric>
      )}
      {showValidadeDemand && (
        <ButtonGeneric
          style={{ color: '#4F92F8' }}
          variant="link"
          className="text-decoration-none align-items-center d-flex "
          onClick={() => setShowModalValidateDemand(true)}
        >
          <ValidateDemandIcon /> <p className="mb-0 ms-1">Aprovar</p>
        </ButtonGeneric>
      )}
    </>
  );

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const submitPrivateDemands = async (selectedUsers) => {
    const processedSelectedRows = selectedRows.filter((row) => !row.private);

    try {
      for (let i = 0; i < processedSelectedRows.length; i += 1) {
        const selectedRow = processedSelectedRows[i];
        // eslint-disable-next-line no-await-in-loop
        await demandsProvider.demandTogglePrivate(
          selectedRow.id,
          {
            users: selectedUsers.map((user) => ({ id: user.value })),
          },
          false,
        );
      }

      toast.success('Demandas atualizadas com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar demandas.');
    } finally {
      await demandsActions.fetchAction();
      setToggledClearRows(true);
    }
  };

  const submitValidateDemands = async () => {
    const processedSelectedRows = selectedRows.filter(
      (row) => row.statusKey === 'FINISHED' && row.validationDate === null,
    );

    try {
      for (let i = 0; i < processedSelectedRows.length; i += 1) {
        const selectedRow = processedSelectedRows[i];
        // eslint-disable-next-line no-await-in-loop
        await demandsProvider.updateDemand(selectedRow.id, {
          validationDate: new Date(),
          validationUserId: authUser.id,
        });
      }

      toast.success('Demandas atualizadas com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar demandas.');
    } finally {
      await demandsActions.fetchAction();
      setToggledClearRows(true);
    }
  };

  let dataTableTextTitle = 'Ordem cronológica';

  if (statusSelected === 'FINISHED') {
    dataTableTextTitle = (
      <>
        <RoundedPill
          id="notValidated"
          validatedSelected={validatedSelected}
          onClick={() => setValidatedSelected(false)}
        >
          Ainda não aprovado por vocês
        </RoundedPill>

        <RoundedPill
          validatedSelected={!validatedSelected}
          onClick={() => setValidatedSelected(true)}
          id="validated"
        >
          Aprovado por vocês
        </RoundedPill>
      </>
    );
  } else if (statusSelected === 'IN_PROGRESS') {
    dataTableTextTitle = (
      <Row>
        <Col xs="auto">Ordem de prioridade</Col>
        <Col className="fs-12 align-self-end ps-0">
          <div
            onClick={() => setShowModalReorderDemand(true)}
            className="cursor-pointer"
          >
            <BiPencil className="me-1" fill="#262626" />
            Reordenar
          </div>
        </Col>
      </Row>
    );
  } else if (statusSelected === 'ALL') {
    dataTableTextTitle = 'Ordem de atualização';
  }

  const dataTableHeader = (
    <Row>
      <Col className="mt-3 mb-2" md="auto">
        <DemandIcon padCustomStyle={{ marginRight: '0px' }} fill="#262626" />
      </Col>
      <Col className="align-self-center ps-0 mt-3 mb-2 fs-16">
        {dataTableTextTitle}
      </Col>
    </Row>
  );

  return (
    <Row className="m-0">
      <Col md="auto">
        <DemandStatusSelector />
      </Col>
      <Col className="overflow-scroll">
        <Card>
          <Table
            columns={columnsDemands}
            pagination
            paginationServer
            sortServer
            fetchFunction={demandsProvider.getDemands}
            dispatchString="DEMANDS_LIST"
            listReducerKey="demandsList"
            unStyled
            hideXmsButton
            selectableRows
            fixedHeader
            fixedHeaderScrollHeight="310px"
            title={dataTableHeader}
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            onRowClicked={(row) => window.open(`/#/demand/${row.id}`, '_blank')}
            clearSelectedRows={toggledClearRows}
            contextMessage={{
              singular: 'demanda selecionada',
              plural: 'demandas selecionadas',
            }}
          />
        </Card>
      </Col>
      <ModalPrivateDemand
        close={() => setShowModalPrivateDemand(false)}
        show={showModalPrivateDemand}
        demandsIdsToPrivate={selectedRows.map((row) => row.id)}
        confirm={submitPrivateDemands}
      />
      <ModalConfirmation
        close={() => setShowModalValidateDemand(false)}
        open={showModalValidateDemand}
        onConfirmation={() => {
          submitValidateDemands().then(() => setShowModalValidateDemand(false));
        }}
        title="Ao confirmar, essas demandas serão aprovadas"
      />
      <ModalReorderDemand
        close={() => setShowModalReorderDemand(false)}
        show={showModalReorderDemand}
      />
    </Row>
  );
}
