/* eslint-disable default-param-last */

const initialState = {
  rows: [],
  page: 1,
  pagePending: 1,
  pagePendingToSign: 1,
  pageSigned: 1,
  totalPendingToSign: 0,
  totalPending: 0,
  totalSigned: 0,
  limit: 10,
  count: 0,
  where: {},
  order: {},
  file: {},
  pendingDocs: [],
  signedDocs: [],
  pendingCurrentSigner: [],
  signatorysList: [],
  showModalSignatorys: false,
  showModalPadSign: false,
};

const SignReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILE':
      return { ...state, file: action.payload };
    case 'SET_SIGNATORY_DATAS':
      return { ...state, ...action.payload };
    case 'SET_SIGNATORYS_LIST':
      return {
        ...state,
        signatorysList: [...state.signatorysList, action.payload.newSignatory],
      };
    case 'REMOVE_SIGNATORY':
      return {
        ...state,
        signatorysList: state.signatorysList.filter(
          (_, index) => index !== action.payload.index,
        ),
      };
    case 'SET_SIGN_LIST':
      return { ...state, ...action.payload };
    case 'SET_PAGE':
      return { ...state, ...action.payload };
    case 'SET_SHOW_MODAL':
      return { ...state, ...action.payload };
    case 'SET_FILE_URL':
      return { ...state, ...action.payload };
    case 'RESET_EMPLOYEES':
      return initialState;
    default:
      return state;
  }
};

export default SignReducer;
