/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import TitlePage from '../../components/Pages/TitlePage';
import CardForContent from '../../components/CardForContent';
import MappingIcon from '../../components/customIcons/MappingIcon';
import Brand from '../../components/customIcons/Brand';
import AnalysisRisk from '../../components/customIcons/analysisRisk';
import CalendarIcon from '../../components/customIcons/CalendarIcon';
import HourEmptyEmptyIcon from '../../components/customIcons/HourGlassEmptyIcon';
import CustomerMappingProvider from '../../providers/CustomerMapping';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';
import demandsProvider from '../../providers/demands';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import SmartLink from '../../components/SmartLink';
import ButtonGeneric from '../../components/Button';
import UserIcon from '../../components/customIcons/UserIcon';
import SignIcon from '../../components/customIcons/Sign';

export default function ToolsIndex() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [AIRDemand, setAIRDemand] = useState(undefined);

  const [showInitiateMappingModal, setShowInitiateMappingModal] =
    useState(false);
  const [showInitiateAIRModal, setShowInitiateAIRModal] = useState(false);
  const [showContractServiceModal, setShowContractServiceModal] =
    useState(false);

  const customerMapping = useSelector((state) => {
    return state.CustomerMappingList;
  });
  const authUser = useSelector((state) => state.auth.auth);

  const fetch = async () => {
    await CustomerMappingProvider.get().then((data) => {
      dispatch({
        type: 'SET_CUSTOMER_MAPPING_LIST',
        payload: { selected: data },
      });
    });

    const demand = await demandsProvider.getDemands({
      where: [
        {
          key: 'demandTypeName',
          value: 'Análise Interna de Risco',
        },
      ],
    });
    setAIRDemand(demand.rows[0]);
  };

  useEffect(() => {
    fetch();
  }, []);

  const redirectToSelectedTool = (tool) => {
    if (
      authUser.Customer?.tags?.find((tag) => tag.name === 'Demanda Específica')
    ) {
      const allowedTools = authUser.Customer.customerAccess?.split(',');

      let acessTranslated = '';

      switch (tool) {
        case 'mapping':
          acessTranslated = 'Mapeamento de dados';
          break;
        case 'marks':
          acessTranslated = 'Registrar marcas';
          break;
        case 'air':
          acessTranslated = 'Análise Interna de Risco';
          break;
        case 'reminder':
          acessTranslated = 'Lembretes';
          break;
        default:
          break;
      }

      if (
        !Array.isArray(allowedTools) ||
        !allowedTools.includes(acessTranslated)
      ) {
        setShowContractServiceModal(true);
        return;
      }
    }
    switch (tool) {
      case 'mapping':
        if (customerMapping?.selected === null) {
          setShowInitiateMappingModal(true);
          return;
        }
        break;
      case 'air':
        if (AIRDemand === undefined) {
          setShowInitiateAIRModal(true);
          return;
        }
        break;
      default:
        break;
    }

    navigate(`/tools/${tool}`);
  };

  return (
    <>
      <TitlePage title="Ferramentas" border="none" />
      <Row className="m-0">
        <Col className="mt-4" md="6" lg="4" xl="3">
          <CardForContent
            onClick={() => redirectToSelectedTool('mapping')}
            className="cursor-pointer"
            Icon={MappingIcon}
            title="Mapeamento de dados"
          >
            Mapeie o fluxo dos dados pessoais no seu negócio após responder
            algumas perguntas.
          </CardForContent>
        </Col>
        <Col className="mt-4" md="6" lg="4" xl="3">
          <CardForContent
            Icon={Brand}
            title="Registrar marcas"
            onClick={() => redirectToSelectedTool('marks')}
            className="cursor-pointer"
          >
            Registre sua marca e acompanhe todo o processo.
          </CardForContent>
        </Col>
        <Col className="mt-4" md="6" lg="4" xl="3">
          <CardForContent
            Icon={AnalysisRisk}
            title="Avaliação interna de risco"
            onClick={() => redirectToSelectedTool('air')}
            className="cursor-pointer"
          >
            Faça a Avaliação interna de riscos de Lavagem de Dinheiro e
            Financiamento do Terrorismo (LD/FT) da sua empresa.
          </CardForContent>
        </Col>
        <Col className="mt-4" md="6" lg="4" xl="3">
          <CardForContent
            Icon={CalendarIcon}
            title="Lembretes"
            onClick={() => redirectToSelectedTool('reminder')}
            className="cursor-pointer"
          >
            Crie lembretes para se organizar melhor e não esquecer de nada.
          </CardForContent>
        </Col>
        <Col className="mt-4" md="6" lg="4" xl="3">
          <CardForContent
            Icon={UserIcon}
            title="NDM RH"
            onClick={() => navigate('/rh/')}
            className="cursor-pointer"
          >
            Área para o gerenciamento de equipe, contração e demissão
          </CardForContent>
        </Col>
        <Col className="mt-4" md="6" lg="4" xl="3">
          <CardForContent
            Icon={SignIcon}
            title="NDM Sign"
            onClick={() => navigate('/sign/')}
            className="cursor-pointer"
          >
            Assine ou envie para parceiros/clientes assinarem qualquer documento
            eletronicamente.
          </CardForContent>
        </Col>
        <Col className="mt-4" md="6" lg="4" xl="3">
          <CardForContent Icon={HourEmptyEmptyIcon} title="Novidades em breve">
            Mais ferramentas estão por vir...
          </CardForContent>
        </Col>
      </Row>
      <ModalConfirmation
        open={showInitiateMappingModal}
        close={() => setShowInitiateMappingModal(false)}
        title="Deseja iniciar seu mapeamento de dados?"
        description="Ao confirmar, será criada uma demanda de reunião inicial para que nosso time apresente a ferramenta."
        onConfirmation={async () => {
          const mapping = await CustomerMappingProvider.newMapping();
          setShowInitiateMappingModal(false);
          navigate(`/demand/${mapping.preMeetingDemandId}`);
        }}
      />
      <ModalConfirmation
        open={showInitiateAIRModal}
        close={() => setShowInitiateAIRModal(false)}
        title="Deseja iniciar a análise interna de risco (LD/FT)?"
        description="Ao confirmar, será criada uma demanda de reunião para que nosso time de compliance apresente a ferramenta. Após a reunião o acesso a ferramenta será concedido."
        onConfirmation={async () => {
          const actualSelectedBusiness = localStorage.getItem('businessId');
          const businessObject = authUser.Businesses.find(
            (business) => business.id === actualSelectedBusiness,
          );

          const demand = await demandsProvider.create({
            name: `${businessObject.name} - Análise Interna de Risco`,
            description:
              'Essa demanda foi criada para que possamos nos reunir e apresentar a ferramenta de Análise Interna de Riscos Lavagem de Dinheiro e Financiamento do Terrorismo (LD/FT). Após a reunião com o time de compliance da NDM, poderá ser feita a análise interna de risco.',
          });
          setShowInitiateAIRModal(false);
          navigate(`/demand/${demand.id}`);
        }}
      />
      <ModalGeneric
        show={showContractServiceModal}
        close={() => setShowContractServiceModal(false)}
      >
        <p className="fs-16 text-justify m-0 mt-4">
          O serviço contratado por você junto a NDM não abrange o uso desta
          ferramenta.
        </p>
        <p className="fs-16 text-justify m-0">
          Caso tenha interesse em conhecer a funcionalidade e entender como pode
          ser útil no seu negócio.
        </p>
        <p className="fs-16 text-justify ">
          Entre em contato conosco pelo e-mail contato@ndmadvogados.com.br ou
          direto pelo whatsapp.
        </p>

        <SmartLink to="mailto:contato@ndmadvogados.com.br">
          <p className="text-center m-0 fs-16">
            <FaEnvelope />
            <span className="ms-2">contato@ndmadvogados.com.br</span>
          </p>
        </SmartLink>
        <Row className="justify-content-center">
          <Col md="auto">
            <SmartLink to="https://api.whatsapp.com/send?phone=553492112558">
              <ButtonGeneric className="text-white mt-4 ms-auto me-auto">
                <FaWhatsapp className="mb-1" size={20} />
                <span className="ms-1">
                  Direto pelo whatsapp, clicando aqui
                </span>
              </ButtonGeneric>
            </SmartLink>
          </Col>
        </Row>
      </ModalGeneric>
    </>
  );
}
