/* eslint-disable default-param-last */
const initialState = {
  userId: '',
};

const messageBox = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MESSAGE_USER_ID':
      return { ...state, ...action.payload };
    case 'RESET_MESSAGE_BOX_REDUCER':
      return initialState;
    default:
      return state;
  }
};

export default messageBox;
