import { toast } from 'react-toastify';
import Api from '.';

const DocumentProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/document', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/document/${id}`);
    return data;
  },
  create: async (tag) => {
    const { data } = await Api.post('/document', {
      ...tag,
      deliveryTime: 2,
    });
    return data;
  },

  inserDocument: async (data, id) => {
    try {
      let url = '/document/folder';
      if (id) {
        url += `/${id}`;
      }
      const response = await Api.post(url, data);
      toast.success('Documento inserido com sucesso');
      return response.data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },
  getByFolderId: async (params, id) => {
    try {
      let url = '/document/folder';
      if (id) {
        url += `/${id}`;
      }
      // colocar essa formatação para o BE
      const { data } = await Api.get(url, { params });
      data.rows = data.rows.map(
        ({ Document, folderId, userId, DocumentsMetrics }) => ({
          ...Document,
          folderId,
          DocumentsMetrics,
          documentRelationUserId: userId,
        }),
      );
      return data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },

  update: async (tag) => {
    const { data } = await Api.patch(`/document/${tag.id}`, tag);
    return data;
  },
  delete: async (tag) => {
    const { data } = await Api.delete(`/document/${tag.id}`);
    return data;
  },
  downloadOnlyDocuments: async (params) => {
    const { data } = await Api.get('/document/download-only-documents', {
      params,
    });
    return data;
  },
  downloadAll: async (params) => {
    const { data } = await Api.get('/document/downloadAll', { params });
    return data;
  },
  ropa: async (params) => {
    const { data } = await Api.get('/document/ropa-route', { params });
    return data;
  },
  changeDocumentRelation: async (relation) => {
    try {
      const { data } = await Api.patch(
        '/document/change-documents-relation',
        relation,
      );
      toast.success('Documento movido com sucesso');
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message);

      return null;
    }
  },
  moveDocumentMass: async (data) => {
    try {
      const response = await Api.patch('/document/move-document-mass', data);
      toast.success('Documento movido com sucesso');
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message);

      return null;
    }
  },
  moveToTrash: async (relation) => {
    try {
      const { documentId, folderId } = relation;
      const { data } = await Api.delete(
        `/document/move-to-trash/${documentId}/${folderId}`,
      );
      toast.success('Documento movido para a lixeira com sucesso');
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message);

      return null;
    }
  },

  deleteDocumentRelation: async (relation) => {
    try {
      const { documentId, folderId } = relation;
      const { data } = await Api.delete(
        `/document/delete-document-relation/${documentId}/${folderId}`,
      );
      toast.success('Documento deletado com sucesso');
      return data;
    } catch (error) {
      toast.error(error?.message);

      return null;
    }
  },

  getPdfUrlToView: async (data) => {
    try {
      const response = await Api.post('/document/getUrlView', data);
      toast.success('Salvo com sucesso');
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getUrlPdfToken: async (data) => {
    try {
      const response = await Api.post('/document/getUrlPdfToken', data);
      toast.success('Salvo com sucesso');
      return response.data;
    } catch (error) {
      return error;
    }
  },
  insertSignDocument: async (data) => {
    try {
      const response = await Api.post('/document/sign', data);
      toast.success('Salvo com sucesso');
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default DocumentProvider;
